/* istanbul ignore file */
import { createSlice } from '@reduxjs/toolkit';
import { PermissionAction, Roles } from '../../actions/Login/actions';

export const INTITIAL_STATE = {
    loading: false,
    isDataFetched: false,
    data: [],
    roles: [],
    error: false,
    errorMessage: '',
};
const PermissionReducer = createSlice({
    name: 'PermissionReducer',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(PermissionAction.pending, state => {
                state.loading = true;
            })
            .addCase(PermissionAction.fulfilled, (state, { payload }) => {
                const permissons = {};
                const { data = [] } = payload;
                data.map(({ permission_code, permission_name }) => {
                    permissons[permission_code] = permission_name;
                });
                state.loading = false;
                state.isDataFetched = true;
                state.data = permissons;
            })
            .addCase(PermissionAction.rejected, (state, { payload }) => {
                state.loading = false;
                state.isDataFetched = true;
                state.data = [];
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(Roles.pending, state => {
                state.loading = true;
            })
            .addCase(Roles.fulfilled, (state, { payload }) => {
                const newData = payload?.data?.records?.length
                    ? payload?.data?.records?.map(item => {
                          return { ...item, is_selected: false };
                      })
                    : [];
                const response = {
                    ...payload,
                    data: {
                        ...payload.data,
                        records: newData,
                    },
                };
                state.loading = false;
                state.roles = response;
            })
            .addCase(Roles.rejected, (state, { payload }) => {
                state.loading = true;
                state.roles = [];
                state.errorMessage = payload;
                state.error = true;
            });
    },
});

export default PermissionReducer.reducer;
