/* istanbul ignore file */
import React, { lazy, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import SecureRoutes from './SecureRoutes';
import E404 from '../E404';
import CallbackComponent from '../CallbackComponent';
import Sidebar from '../Layout/Sidebar';
import AuthStore from '../../common/AuthStore';
import { CONFIG, PATHS } from '../../global-constants';
import ClientsUsers from '../User/Clients/ClientsUsers';
import Analytics from '../Analytics';
import { useSelector } from 'react-redux';
import Home from '../../containers/KnowledgeCenter/Home';
import OverviewPage from '../../containers/KnowledgeCenter/OverviewPage';
import PreSurveyPage from '../../containers/KnowledgeCenter/PreSurveyPage';
import SetupSurveyPage from '../../containers/KnowledgeCenter/SetupSurveyPage';
import LiveSurveyPage from '../../containers/KnowledgeCenter/LiveSurveyPage';
import AnalysisPage from '../../containers/KnowledgeCenter/AnalysisPage';
import LandingPage from '../../containers/KnowledgeCenter/LandingPage';
import GlossaryPage from '../../containers/KnowledgeCenter/GlossaryPage';
import VersionPage from '../../containers/KnowledgeCenter/version';

const RoutesComponent = ({ mid }) => {
    const {
        data: {
            can_view_survey_tab = '',
            can_create_survey = '',
            can_view_analytics_tab = '',
            can_view_help_tab = '',
            can_view_monitor_tab = '',
            can_view_user_management_tab = '',
            can_update_clients = '',
            can_create_clients = '',
        },
        isDataFetched,
    } = useSelector(({ permissionReducer }) => permissionReducer);

    const Dashboard = lazy(() => import('../Dashboard'));
    const Client = lazy(() => import('../User/partials/Client'));
    const Surveys = lazy(() => import('../Surveys'));
    const StepComponent = lazy(() => import('../Surveys/partials/create/'));
    const Monitor = lazy(() => import('../Monitor'));
    const MonitorSurvey = lazy(() => import('../Monitor/partials/monitor-survey'));

    const accessToken = AuthStore.accessToken;
    const [isDeepLink, setIsDeepLink] = useState(null);

    if (window.location.pathname === '/') {
        window.location.href = CONFIG.BASE_UI_PATH;
    }

    return (
        <BrowserRouter basename={CONFIG.BASE_UI_PATH}>
            <Routes>
                <Route path={PATHS.AUTH_CALL} element={<CallbackComponent mid={mid} />} />

                <Route
                    path={PATHS.HOME}
                    element={
                        <SecureRoutes mid={mid} token={AuthStore.accessToken}>
                            <Sidebar />
                            <Dashboard />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.DASHBOARD}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={AuthStore.accessToken}
                            isLoading={!isDataFetched}
                        >
                            <Sidebar />
                            <Dashboard />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.CLIENT}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={accessToken}
                            isAdmin={true}
                            canAccess={
                                AuthStore.isAdmin && can_view_user_management_tab === 'can_view_user_management_tab'
                            }
                            isLoading={!isDataFetched && can_view_user_management_tab === ''}
                        >
                            <Sidebar />
                            <ClientsUsers />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.EDIT_CLIENT}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={accessToken}
                            isAdmin={true}
                            canAccess={can_update_clients === 'can_update_clients'}
                            isLoading={!isDataFetched && can_update_clients === ''}
                        >
                            <Sidebar />
                            <Client />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.ADD_CLIENT}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            isAdmin={true}
                            canAccess={can_create_clients === 'can_create_clients'}
                            isLoading={!isDataFetched && can_create_clients === ''}
                        >
                            <Sidebar />
                            <Client />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.SURVEYS}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={can_view_survey_tab === 'can_view_survey_tab'}
                            isLoading={!isDataFetched && can_view_survey_tab === ''}
                        >
                            <Sidebar />
                            <Surveys />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={`${PATHS.CREATE_SURVEY}/:tid/:sid?`}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={can_create_survey === 'can_create_survey'}
                            isLoading={!isDataFetched && can_create_survey === ''}
                        >
                            <StepComponent />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.MONITOR}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={can_view_monitor_tab === 'can_view_monitor_tab'}
                            isLoading={!isDataFetched && can_view_monitor_tab === ''}
                        >
                            <Sidebar />
                            <Monitor />
                        </SecureRoutes>
                    }
                >
                    <Route path={`${PATHS.MONITOR_SURVEY}/:id`} element={<MonitorSurvey />} />
                </Route>

                <Route
                    path={PATHS.ANALYTICS}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={can_view_analytics_tab === 'can_view_analytics_tab'}
                            isLoading={!isDataFetched && can_view_analytics_tab === ''}
                        >
                            <Sidebar />
                            <Analytics />
                        </SecureRoutes>
                    }
                />

                <Route
                    path={PATHS.KNOWLEDGE_CENTER}
                    element={
                        <SecureRoutes
                            mid={mid}
                            token={AuthStore.accessToken}
                            canAccess={can_view_help_tab === 'can_view_help_tab'}
                            isLoading={!isDataFetched && can_view_analytics_tab === ''}
                        >
                            {isDeepLink === false ? <Sidebar /> : null}
                            <Home isDeepLink={isDeepLink} setIsDeepLink={setIsDeepLink} />
                        </SecureRoutes>
                    }
                >
                    <Route path={PATHS.KNOWLEDGE_CENTER} element={<VersionPage />} />
                    <Route path={PATHS.KNOWLEDGE_CENTER_HOME} element={<LandingPage />} />
                    <Route path={PATHS.KC_OVERVIEW} element={<OverviewPage />} />
                    <Route path={PATHS.KC_PRESURVEY} element={<PreSurveyPage />} />
                    <Route path={PATHS.KC_SETUP_SURVEY} element={<SetupSurveyPage />} />
                    <Route path={PATHS.KC_LIVE_SURVEY} element={<LiveSurveyPage />} />
                    <Route path={PATHS.KC_ANALYSIS} element={<AnalysisPage />} />
                    <Route path={PATHS.KC_GLOSSARY} element={<GlossaryPage />} />
                </Route>

                <Route path="*" element={<E404 />} />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
