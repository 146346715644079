import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { DeploymentContainer } from './styles';
import TimelineAccordian from './TimelineAccordian';
import GanttChart from '../../../components/KnowledgeCenter/GanttChart';
import { PRESURVEY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh } from '../../../common/colors';

const DeploymentTimeline = ({ deploymentSurveyPhases, timelineActivities, timelineDurationData }) => {
    return (
        <FlexColumnWithAlign gap={pxToVh(29)} className="full-width">
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={PRESURVEY_TEXT.DEPLOYMENT_SECTION_HEADING}
                fontWeight={500}
            />
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={20}
                lineHeight={32}
                label={PRESURVEY_TEXT.DEPLOYMENT_SECTION_DESCRIPTION}
            />
            <DeploymentContainer gap={pxToVh(40)}>
                {deploymentSurveyPhases.map((data, index) => {
                    const isEven = (index + 1) % 2 === 0;
                    return <TimelineAccordian key={data.key} isEven={isEven} data={data} showPhaseDivider={false} />;
                })}
            </DeploymentContainer>
            <FlexColumnWithAlign gap={pxToVh(22)} margin={`${pxToVh(70)}vh 0 0 0`}>
                <GanttChart timelineActivities={timelineActivities} timelineDurationData={timelineDurationData} />
            </FlexColumnWithAlign>
        </FlexColumnWithAlign>
    );
};

DeploymentTimeline.defaultProps = {
    deploymentSurveyPhases: [],
    timelineActivities: [],
    timelineDurationData: [],
};

DeploymentTimeline.propTypes = {
    deploymentSurveyPhases: PropTypes.arrayOf(PropTypes.shape({})),
    timelineActivities: PropTypes.arrayOf(PropTypes.shape({})),
    timelineDurationData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DeploymentTimeline;
