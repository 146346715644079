import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import Text from '../../../components/KnowledgeCenter/Text';
import Button from '../../../components/KnowledgeCenter/Button';
import {
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    FlexBetween,
    FlexColumnWithAlign,
} from '../../../common/styled/styled';
import { PhaseContentContainer, TaskChecklistContainer, ChecklistItem } from '../SetupSurveyPage/styles';
// import TimelineAccordian from '../PreSurveyPage/TimelineAccordian';
import cloudDownload from '../../../assets/img/cloud-download.svg';
import checkIcon from '../../../assets/img/marked.svg';
import { LABELS, LIVE_SURVEY_TEXT, CONFIG } from '../../../utils/kc-constants';
import { downloadURL } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const SurveyLiveOverview = ({ overviewData, taskChecklistData }) => {
    console.log(overviewData);
    return (
        <FlexColumnWithAlign className="full-width">
            {/* <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={LIVE_SURVEY_TEXT.OVERVIEW_SECT_HEADING}
                fontWeight={500}
            />
            <FlexColumnWithAlign gap={pxToVh(12)} margin={`${pxToVh(24)}vh 0 0 0`} className="full-width">
                <PhaseContentContainer>
                    <TimelineAccordian data={overviewData} isEven={false} showPhaseDivider={false} />
                </PhaseContentContainer>
                {overviewData?.note_text ? (
                    <Text
                        fontColor={colorsCode.TextSubtle}
                        fontSize={16}
                        lineHeight={24}
                        label={overviewData?.note_text}
                        fontWeight={300}
                        className="note-text"
                    />
                ) : null}
            </FlexColumnWithAlign> */}
            <TaskChecklistContainer style={{ margin: 0 }}>
                <FlexBetween className="full-width" alignItems="flex-start">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={24}
                        lineHeight={28}
                        label={LIVE_SURVEY_TEXT.TASK_CHECKLIST_HEADING}
                        fontWeight={500}
                    />
                    <Button
                        fontSize={16}
                        lineHeight={24}
                        label={LABELS.DOWNLOAD_CHECKLIST_CTA}
                        startIcon={<img src={cloudDownload} alt="cloud-download" />}
                        padding={`${pxToVh(8)}vh ${pxToVw(16)}vw`}
                        isUppercase={false}
                        onClick={() =>
                            downloadURL(
                                `${CONFIG.BASE_IMG_URL}/Phase_II_Survey_Live_Task checklist_summary_of_activities.pptx`
                            )
                        }
                    />
                </FlexBetween>
                <FlexColumnWithAlign gap={pxToVh(16)} margin={`${pxToVh(20)}vh 0 0 0`}>
                    <PhaseContentContainer>
                        {taskChecklistData?.options?.map((data, index) => {
                            return (
                                <AccordionStyled key={data.key} defaultExpanded={index === 0}>
                                    <AccordionSummaryStyled
                                        expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                    >
                                        <Text
                                            fontColor={colorsCode.TextHeading}
                                            fontSize={16}
                                            lineHeight={24}
                                            label={data.heading}
                                            fontWeight={500}
                                        />
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <FlexColumnWithAlign gap={pxToVh(12)}>
                                            {data?.steps?.map((step, index) => {
                                                return (
                                                    <ChecklistItem
                                                        key={`step_${index}`}
                                                        gap={pxToVw(12)}
                                                        alignItems="flex-start"
                                                        isTimeIntensive={step.is_time_intensive}
                                                    >
                                                        <img src={checkIcon} alt="check" />
                                                        <Text
                                                            fontColor={colorsCode.Neutral80}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            label={step.text}
                                                        />
                                                    </ChecklistItem>
                                                );
                                            })}
                                        </FlexColumnWithAlign>
                                    </AccordionDetailsStyled>
                                </AccordionStyled>
                            );
                        })}
                    </PhaseContentContainer>
                </FlexColumnWithAlign>
            </TaskChecklistContainer>
        </FlexColumnWithAlign>
    );
};

SurveyLiveOverview.defaultProps = {
    overviewData: {},
    taskChecklistData: {},
};

SurveyLiveOverview.propTypes = {
    overviewData: PropTypes.shape({
        note_text: PropTypes.string,
    }),
    taskChecklistData: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({})),
        note_text: PropTypes.string,
    }),
};

export default SurveyLiveOverview;
