import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import {
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    FlexColumnWithAlign,
    FlexColumn,
    FlexColumnGap,
} from '../../../common/styled/styled';
import { colorsCode, pxToVh } from '../../../common/colors';
import { ExpandMore } from '@mui/icons-material';
import workplace from '../../../assets/img/work_place.svg';
import { CircleImage, Image } from '../OverviewPage/styles';

const WorkPlace = ({ data }) => {
    const section_images = [workplace];

    return (
        <FlexColumnGap gap={pxToVh(20)}>
            <Text
                fontColor={colorsCode.TextHeading}
                fontSize={36}
                lineHeight={44}
                fontWeight={500}
                label={data.sub_title}
            />
            <FlexColumnWithAlign gap={0} style={{ marginTop: '20px', width: '83%' }}>
                {data.options.map((option, index) => {
                    return (
                        <FlexColumnWithAlign key={option.key} className="full-width">
                            <CircleImage>
                                <Image src={section_images[index]} alt={`key-activity-${index}`} />
                            </CircleImage>
                            <Text
                                fontColor={colorsCode.title}
                                fontSize={20}
                                lineHeight={32}
                                fontWeight={500}
                                label={option.title}
                            />
                            <FlexColumn className="full-width" style={{ marginBottom: `${pxToVh(0)}vh` }}>
                                {option.steps.map(step => {
                                    return (
                                        <AccordionStyled
                                            key={step.key}
                                            defaultExpanded={step.isOpen}
                                            paddingBottom={8}
                                            noDoubleBorder
                                        >
                                            <AccordionSummaryStyled
                                                style={{ paddingLeft: '0px' }}
                                                expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                            >
                                                <Text
                                                    fontColor={colorsCode.title}
                                                    fontSize={16}
                                                    lineHeight={24}
                                                    fontWeight={500}
                                                    label={step.title}
                                                />
                                            </AccordionSummaryStyled>
                                            <AccordionDetailsStyled style={{ paddingTop: '0px' }}>
                                                <Text
                                                    fontColor={colorsCode.TextSubtle}
                                                    fontSize={16}
                                                    lineHeight={24}
                                                    fontWeight={400}
                                                    label={step.body}
                                                />
                                            </AccordionDetailsStyled>
                                        </AccordionStyled>
                                    );
                                })}
                            </FlexColumn>
                        </FlexColumnWithAlign>
                    );
                })}
            </FlexColumnWithAlign>
            <div style={{ width: '83%' }}>
                {' '}
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={36}
                    lineHeight={44}
                    fontWeight={500}
                    label={data.html}
                />
            </div>
        </FlexColumnGap>
    );
};

WorkPlace.defaultProps = {
    data: {},
};

WorkPlace.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default WorkPlace;
