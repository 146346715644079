import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FootNote } from '../../../common/styled/styled';
import Tooltip from '../../../components/KnowledgeCenter/Tooltip';
import { ListContainer } from '../../../components/KnowledgeCenter/ObjectiveComponent/styles';
import TextWithFootNote from '../../../components/KnowledgeCenter/TextWithFootNote';
import { colorsCode, pxToVh } from '../../../common/colors';

const ContentWithFootNotes = ({ data }) => {
    return (
        <ListContainer listType={data.list_type}>
            {data?.listing_data?.map((list, liIndex) => {
                return (
                    <li key={`listing-data-${data.heading.text}-${liIndex}`}>
                        {list?.heading?.text ? (
                            <TextWithFootNote
                                fontColor={colorsCode.Neutral80}
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={500}
                                component="p"
                                footNote={data.heading?.foot_note?.tooltip_text}
                                footerLabel={data.heading?.foot_note?.label}
                                label={list.heading.text}
                            />
                        ) : null}
                        <TextWithFootNote
                            fontColor={colorsCode.Neutral80}
                            fontSize={16}
                            lineHeight={24}
                            footNote={list.foot_note?.tooltip_text}
                            footerLabel={list.foot_note?.label}
                            label={list.body}
                        >
                            {list.children && <ChildrenRender data={list.children} />}
                        </TextWithFootNote>
                    </li>
                );
            })}
        </ListContainer>
    );
};

const ChildrenRender = ({ data }) => {
    return (
        <ListContainer margin={`${pxToVh(16)}vh 0`} listType={data.list_type}>
            {data?.map(li => {
                return (
                    <Text key={li.body} fontColor={colorsCode.Neutral80} fontSize={16} lineHeight={24} component="li">
                        {li.body}
                        {li.foot_note && (
                            <Tooltip text={li.foot_note.tooltip_text}>
                                <FootNote>
                                    <Text
                                        fontColor={colorsCode.SecondaryDeepBlue500}
                                        fontSize={12}
                                        lineHeight={14}
                                        fontWeight={300}
                                        label={li.foot_note.label}
                                        cursor="default"
                                    />
                                </FootNote>
                            </Tooltip>
                        )}
                        {li.children && <ChildrenRender data={li.children} />}
                    </Text>
                );
            })}
        </ListContainer>
    );
};

ChildrenRender.defaultProps = {
    data: {},
};

ChildrenRender.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
};
ContentWithFootNotes.defaultProps = {
    data: {},
};

ContentWithFootNotes.propTypes = {
    data: PropTypes.shape({
        listing_data: PropTypes.arrayOf(PropTypes.shape({})),
        heading: PropTypes.shape({
            text: PropTypes.string,
            foot_note: PropTypes.shape({
                tooltip_text: PropTypes.string,
                label: PropTypes.string,
            }),
        }),
        list_type: PropTypes.string,
    }),
};

export default ContentWithFootNotes;
