import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { OHIContentContainer, ScoreCardsContainer } from './styles';
import { KC_VERSION_KEYS, ORGHEALTH_OVERVIEW_TEXT } from '../../../utils/kc-constants';
import ScoreCard from '../../../components/KnowledgeCenter/ScoreCard';
import OverviewCard from '../../../components/KnowledgeCenter/OverviewCard';
import PracticeCard from '../../../components/KnowledgeCenter/PracticeCard';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { useLocation } from 'react-router';
import { useEffect, useState } from 'react';

const OHIContent = ({ data, scoreData, definitionData, practiceData, onClickPractice }) => {
    const [isVersionFour, setVersion] = useState(false);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, [isVersionFour, setVersion]);

    return (
        <OHIContentContainer gap={pxToVh(60)}>
            <FlexColumnWithAlign gap={pxToVh(29)}>
                <Text
                    fontColor={colorsCode.PrimaryDeepBlue}
                    fontSize={36}
                    lineHeight={44}
                    fontWeight={500}
                    label={data.heading}
                />
                <Text fontColor={colorsCode.Neutral80} fontSize={16} lineHeight={24} label={data.body} />
            </FlexColumnWithAlign>
            <ScoreCardsContainer gap={`${pxToVh(16)}vh ${pxToVw(16)}vw`} flexWrap="wrap">
                {scoreData.map(item => {
                    return (
                        <ScoreCard heading={item.title} key={item.title} subheading={item.subtext} icon={item.icon} />
                    );
                })}
            </ScoreCardsContainer>
            <FlexColumnWithAlign gap={pxToVh(40)}>
                <Text
                    fontColor={colorsCode.Neutral80}
                    fontSize={24}
                    lineHeight={28}
                    fontWeight={500}
                    label={ORGHEALTH_OVERVIEW_TEXT.OHI_SURVEY_DESCRIPTION}
                />
                <FlexRow gap={pxToVw(40)} alignItems="unset">
                    {definitionData.map(item => {
                        return (
                            <OverviewCard
                                title={item.title}
                                key={item.title}
                                heading={item.heading}
                                image={item.image_url}
                                text={item.text}
                                height="auto"
                                width="100%"
                            />
                        );
                    })}
                </FlexRow>
            </FlexColumnWithAlign>
            <FlexColumnWithAlign gap={pxToVh(40)}>
                <FlexColumnWithAlign gap={pxToVh(12)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={24}
                        lineHeight={28}
                        fontWeight={500}
                        label={ORGHEALTH_OVERVIEW_TEXT.OHI_PRACTICES_HEADING}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        label={ORGHEALTH_OVERVIEW_TEXT.OHI_PRACTICES_DESCRIPTION}
                    />
                </FlexColumnWithAlign>
                <FlexColumnWithAlign gap={pxToVh(52)}>
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={18}
                        lineHeight={28}
                        fontWeight={500}
                        label={
                            isVersionFour
                                ? ORGHEALTH_OVERVIEW_TEXT.OHI_PRACTICES_OUTCOMES_4
                                : ORGHEALTH_OVERVIEW_TEXT.OHI_PRACTICES_OUTCOMES
                        }
                    />
                    <FlexRow gap={pxToVw(20)} flexWrap="wrap">
                        {practiceData.map(item => {
                            return (
                                <PracticeCard
                                    key={item.id}
                                    heading={item.title}
                                    icon={item.icon}
                                    height="auto"
                                    width="auto"
                                    onClick={() => onClickPractice(item?.id)}
                                />
                            );
                        })}
                    </FlexRow>
                </FlexColumnWithAlign>
            </FlexColumnWithAlign>
        </OHIContentContainer>
    );
};

OHIContent.defaultProps = {
    data: {},
    scoreData: [],
    definitionData: [],
    practiceData: [],
    onClickPractice: () => {},
};

OHIContent.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.string,
        body: PropTypes.string,
        video_url: PropTypes.string,
        thumbnail: PropTypes.string,
    }),
    scoreData: PropTypes.arrayOf(PropTypes.shape({})),
    definitionData: PropTypes.arrayOf(PropTypes.shape({})),
    practiceData: PropTypes.arrayOf(PropTypes.shape({})),
    onClickPractice: PropTypes.func,
};

export default OHIContent;
