import React, { Fragment, memo, useCallback, useEffect } from 'react';
import { Paper, TableRow, TableHead, TableBody, Table, Checkbox } from '@mui/material';
import SortIcon from '../../../../assets/img/sort-icon.svg';
import EditIcon from '../../../../assets/img/edit_blue.svg';
import DeleteIcon from '../../../../assets/img/delete_blue.svg';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { FlexBetween, TableCellStyled } from '../../../../common/styled/styled';
import PaginationComponent from '../../PaginationComponent';
import { DeleteDialog, SuccessModal } from '../../../../common/DialogModal';
import { LABELS, MESSAGE } from '../../../../global-constants';
import { useSelector } from 'react-redux';
import { closeModal, openModal } from '../../../../store/reducers/slice/Modal';
import { resetData } from '../../../../store/reducers/slice/Users';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { UserDetails } from '../../../../store/actions/Users/actions';
import { resetSearched } from '../../../../store/reducers/slice/SearchUserClientSlice';
import { resetSaved } from '../../../../store/reducers/slice/ClientSlice';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { Logout } from '../../../../common/api/login';
import { ShowMessage } from '../../../../common/FormInputs/Snackbar';
import { closePopUp } from '../../../../store/reducers/slice/SnackbarSlice';

const UserList = ({
    usersData = [],
    userFilters,
    setUserFilters,
    totalUsersCount,
    data: permission = {},
    getRoleName,
    dispatch,
    UsersListing,
    setUsersData,
    setUserId,
    deleteUser,
    open,
    setOpen,
}) => {
    const {
        isDeleted: userDeleted,
        error,
        isSaved: userSaved,
        isUpdated: userUpdated,
        isUpdatedSelf,
        errorMessage,
    } = useSelector(state => state.userReducer);
    const { isOpen } = useSelector(state => state.dialogReducer);
    const { open: openSnackbar = false, type: statusType = '' } = useSelector(state => state.snackbarReducer);
    const { Success_Delete_User, Success_Add_User, Success_Edit_User, Success_Title, Detail_change_Logout_note } =
        MESSAGE;
    const styleObj = {
        width: `${pxToVw(23)}vw`,
        height: `${pxToVh(23)}vh`,
        color: colorsCode.TextHeading,
    };
    const checkBoxStyle = {
        width: `${pxToVw(18)}vw`,
        height: `${pxToVh(18)}vh`,
    };
    const actionableIconStyle = {
        width: `${pxToVw(16)}vw`,
        height: `${pxToVh(16)}vh`,
    };

    useEffect(() => {
        dispatch(closePopUp());
        return () => {
            dispatch(closePopUp());
        };
    }, []);

    useEffect(() => {
        if (isUpdatedSelf) {
            dispatch(openModal());
        }
    }, [isUpdatedSelf]);

    const handleEdit = uuid => {
        dispatch(UserDetails({ user_id: uuid }));
    };

    const handleDelete = id => {
        setUserId(id);
        setOpen(true);
    };

    const onClose = () => {
        setUserId(null);
        setOpen(false);
    };

    const changePageNumber = (e, value) => {
        const copy = cloneDeep(userFilters);
        copy.page = value;
        dispatch(UsersListing(copy));
        setUserFilters(copy);
    };

    const changeLimitPerPage = val => {
        const copy = cloneDeep(userFilters);
        copy.limit = val;
        copy.page = 1;
        dispatch(UsersListing(copy));
        setUserFilters(copy);
    };

    const onCloseSuccess = () => {
        if (isUpdatedSelf) {
            Logout();
        } else {
            dispatch(closeModal());
            dispatch(resetData());
            dispatch(resetSearched());
            dispatch(resetSaved());
        }
    };

    const handleParent = e => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(usersData);
        const data = copy.map(item => {
            return { ...item, is_selected: checked };
        });
        setUsersData(data);
    };

    const handleCheckChild = (e, i) => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(usersData);
        copy[i].is_selected = checked;
        setUsersData(copy);
    };

    const indeterminateFlag = () => {
        if (!usersData?.filter(({ is_selected }) => is_selected === true).length) {
            return false;
        } else if (usersData?.filter(({ is_selected }) => is_selected).length !== usersData?.length) {
            return true;
        }
        return false;
    };

    const getMessage = () => {
        if (error) {
            return errorMessage;
        }
        if (isUpdatedSelf) {
            return Detail_change_Logout_note;
        }
        if (userDeleted) {
            return Success_Delete_User;
        }
        if (userSaved) {
            return Success_Add_User;
        }
        if (userUpdated) {
            return Success_Edit_User;
        }
    };

    const handleSort = () => {
        const copy = cloneDeep(userFilters);
        const {
            filters: { sort = 0 },
        } = copy;
        copy.filters.sort = sort === 0 ? 1 : 0;
        setUserFilters(copy);
        dispatch(UsersListing(copy));
    };

    const handleCloseSnackbar = useCallback(() => {
        dispatch(closePopUp());
        dispatch(resetData());
        dispatch(resetSearched());
        dispatch(resetSaved());
    }, [dispatch]);

    return (
        <Fragment>
            <Table component={Paper} sx={{ boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26' }}>
                <TableHead>
                    <TableRow>
                        <TableCellStyled>
                            <Checkbox
                                sx={{
                                    height: 0,
                                    width: 0,
                                }}
                                icon={<img src={CheckboxSvg} className="img" style={checkBoxStyle} />}
                                inputProps={{ 'data-testid': 'parent' }}
                                checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                onClick={handleParent}
                                checked={Boolean(
                                    usersData?.length && usersData?.every(({ is_selected }) => is_selected === true)
                                )}
                                indeterminate={indeterminateFlag()}
                                indeterminateIcon={<IndeterminateCheckBoxIcon sx={styleObj} />}
                            />
                        </TableCellStyled>
                        <TableCellStyled>
                            <FlexBetween style={{ alignSelf: 'stretch' }}>
                                User name
                                <img
                                    src={SortIcon}
                                    alt="image"
                                    className="img sort"
                                    onClick={handleSort}
                                    style={{ cursor: 'pointer', ...actionableIconStyle }}
                                />
                            </FlexBetween>
                        </TableCellStyled>
                        <TableCellStyled>Role</TableCellStyled>
                        <TableCellStyled>Client name</TableCellStyled>
                        <TableCellStyled>Email ID</TableCellStyled>
                        <TableCellStyled>Survey assigned</TableCellStyled>
                        <TableCellStyled>Last login</TableCellStyled>
                        <TableCellStyled></TableCellStyled>
                        <TableCellStyled></TableCellStyled>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {usersData?.length ? (
                        usersData.map(
                            (
                                {
                                    name = '',
                                    email = '',
                                    uuid = '',
                                    role_id = '',
                                    last_login_at = '',
                                    is_selected = false,
                                    client_name = '',
                                    survey_ids = '',
                                },
                                i
                            ) => (
                                <TableRow key={uuid}>
                                    <TableCellStyled>
                                        <Checkbox
                                            value={uuid}
                                            id={`child-${uuid}`}
                                            name={`child-${uuid}`}
                                            inputProps={{ 'data-testid': 'handlecheck' }}
                                            checkedIcon={<CheckBoxIcon sx={styleObj} />}
                                            onClick={e => handleCheckChild(e, i)}
                                            checked={Boolean(is_selected)}
                                            sx={{
                                                height: 0,
                                                width: 0,
                                            }}
                                            icon={<img src={CheckboxSvg} className="img" style={checkBoxStyle} />}
                                        />
                                    </TableCellStyled>
                                    <TableCellStyled>{name}</TableCellStyled>
                                    <TableCellStyled className="tblecellStyled">{getRoleName(role_id)}</TableCellStyled>
                                    <TableCellStyled className="tblecellStyled">
                                        {client_name?.replaceAll('&amp;', '&')}
                                    </TableCellStyled>
                                    <TableCellStyled className="tblecellStyled">{email}</TableCellStyled>
                                    <TableCellStyled className="tblecellStyled" title={survey_ids.toUpperCase()}>
                                        {survey_ids.length > 20
                                            ? survey_ids.toUpperCase().slice(0, 20) + '...'
                                            : survey_ids.toUpperCase()}
                                    </TableCellStyled>
                                    <TableCellStyled className="tblecellStyled">
                                        {last_login_at
                                            ? `${moment.unix(last_login_at).format('DD-MMM-YYYY | hh:mm A')} (UTC)`
                                            : 'NA'}
                                    </TableCellStyled>
                                    <TableCellStyled>
                                        {permission?.can_update_users === 'can_update_users' ? (
                                            <img
                                                src={EditIcon}
                                                alt="edit img"
                                                onClick={() => handleEdit(uuid)}
                                                style={{
                                                    cursor: 'pointer',
                                                    ...actionableIconStyle,
                                                }}
                                            />
                                        ) : null}
                                    </TableCellStyled>
                                    <TableCellStyled>
                                        {permission?.can_delete_users === 'can_delete_users' ? (
                                            <img
                                                src={DeleteIcon}
                                                alt="delete img"
                                                onClick={() => handleDelete(uuid)}
                                                style={{
                                                    cursor: 'pointer',
                                                    ...actionableIconStyle,
                                                }}
                                            />
                                        ) : null}
                                    </TableCellStyled>
                                </TableRow>
                            )
                        )
                    ) : (
                        <TableRow>
                            <TableCellStyled colSpan={9} sx={{ textAlign: 'center' }}>
                                No user found
                            </TableCellStyled>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
            {usersData?.length && totalUsersCount > userFilters.limit ? (
                <PaginationComponent
                    page={userFilters.page}
                    handleChangeFn={changePageNumber}
                    handleItemPerPageFn={changeLimitPerPage}
                    count={Math.ceil(totalUsersCount / userFilters.limit)}
                    perPage={userFilters?.limit}
                />
            ) : null}
            <DeleteDialog
                open={open}
                close={onClose}
                heading={LABELS.DELETE_TITLE}
                description={LABELS.CONFIRM_DEL_USER_MSG_DESC}
                confirmationText={LABELS.CONFIRM_DEL_MSG}
                cancelText={LABELS.CANCEL_BTN}
                confirmText={LABELS.DELETE_BTN}
                onConfirm={deleteUser}
            />
            {isOpen ? (
                <SuccessModal
                    open={isOpen}
                    title={Success_Title}
                    message={Detail_change_Logout_note}
                    onClose={onCloseSuccess}
                    confirmFlag={false}
                    type="success"
                    btnCancel="Cancel"
                />
            ) : null}
            {openSnackbar || error ? (
                <ShowMessage
                    open={openSnackbar || error}
                    type={statusType && !error ? 'success' : 'error'}
                    message={getMessage()}
                    close={handleCloseSnackbar}
                />
            ) : null}
        </Fragment>
    );
};

UserList.propTypes = {
    usersData: PropTypes.array,
    userFilters: PropTypes.object,
    setUserFilters: PropTypes.func.isRequired,
    totalUsersCount: PropTypes.number,
    data: PropTypes.object.isRequired,
    getRoleName: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    UsersListing: PropTypes.func.isRequired,
    setUsersData: PropTypes.func.isRequired,
    setUserId: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    setOpen: PropTypes.func.isRequired,
};

export default memo(UserList);
