import { makeStyles } from '@mui/styles';
import { pxToVh } from '../../../common/colors';

export const LoginTheme = makeStyles(() => {
    return {
        title: {
            color: '#FFF9EF !important',
            fontSize: `${pxToVh(64)}vh !important`,
            fontWeight: '700 !important',
            textAlign: 'center !important',
            fontFamily: 'Bower !important',
            lineHeight: `${pxToVh(76)}vh !important`,
        },
    };
});
