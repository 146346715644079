import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumnWithAlign, Section } from '../../../common/styled/styled';
import { useIntersection } from '../../../utils/functions';
import { AppContext } from '../Home';
import { KC_VERSION_KEYS, ORGHEALTH_OVERVIEW_TEXT, SECTION_KEYS } from '../../../utils/kc-constants';
import {
    ORGHEALTH_OVERVIEW_DATA,
    OHI_DATA,
    OHI_SCORE_DATA,
    OHI_DEFINITION_DATA,
    OHI_PRACTICE_DATA,
    OHI_PRACTICE_DETAIL_DATA,
    OHI_DEFINITION_DATA_4,
    EX_EXPLANATION_DATA,
    OHI_PRACTICE_DETAIL_DATA_4,
} from '../../../utils/kc-data';
import PracticeDetailModal from '../../../components/KnowledgeCenter/Modals/PracticeDetailModal';
import VideoModal from '../../../components/KnowledgeCenter/Modals/VideoModal';
import OrgHealthContent from './OrgHealthContent';
import OHIContent from './OHIContent';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import ExExplanation from './ExExplanation';
import { useLocation } from 'react-router';

const OverviewPage = () => {
    const refs = useRef([]);
    const { setActiveSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);
    const [showVideoModal, setShowVideModal] = useState({ show: false, url: null });
    const [showPracticeModal, setShowPracticeModal] = useState({ show: false, id: null });

    const [isVersionFour, setVersion] = useState(false);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, [isVersionFour, setVersion]);

    return (
        <Fragment>
            <Section gap={pxToVh(81)} padding={`${pxToVh(70)}vh ${pxToVw(51)}vw ${pxToVh(113)}vh ${pxToVw(60)}vw`}>
                <FlexColumnWithAlign
                    ref={element => refs.current.push(element)}
                    id={SECTION_KEYS.ORGHEALTH}
                    gap={pxToVh(32)}
                >
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        fontWeight={500}
                        label={ORGHEALTH_OVERVIEW_TEXT.ORGHEALTH_SECTION_HEADING}
                    />
                    <OrgHealthContent data={ORGHEALTH_OVERVIEW_DATA} />
                </FlexColumnWithAlign>
                <FlexColumnWithAlign ref={element => refs.current.push(element)} id={SECTION_KEYS.OHI} gap={pxToVh(32)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        fontWeight={500}
                        label={ORGHEALTH_OVERVIEW_TEXT.OHI_SECTION_HEADING}
                    />
                    <OHIContent
                        data={OHI_DATA}
                        scoreData={OHI_SCORE_DATA}
                        definitionData={isVersionFour ? OHI_DEFINITION_DATA_4 : OHI_DEFINITION_DATA}
                        practiceData={OHI_PRACTICE_DATA}
                        onClickPlay={url => setShowVideModal({ show: true, url })}
                        onClickPractice={id => setShowPracticeModal({ show: true, id })}
                    />
                </FlexColumnWithAlign>
                {isVersionFour && (
                    <Section padding={`${pxToVh(0)}vh ${pxToVw(0)}vw ${pxToVh(0)}vh`}>
                        <ExExplanation data={EX_EXPLANATION_DATA} />
                    </Section>
                )}
            </Section>
            <VideoModal
                open={showVideoModal.show}
                onClose={() => setShowVideModal({ show: false, url: null })}
                url={showVideoModal.url}
            />
            <PracticeDetailModal
                open={showPracticeModal.show}
                onClose={() => setShowPracticeModal({ show: false, id: null })}
                data={
                    isVersionFour
                        ? OHI_PRACTICE_DETAIL_DATA_4[showPracticeModal.id]
                        : OHI_PRACTICE_DETAIL_DATA[showPracticeModal.id]
                }
            />
        </Fragment>
    );
};

OverviewPage.defaultProps = {};

OverviewPage.propTypes = {};

export default OverviewPage;
