/* istanbul ignore file */
import { createTheme } from '@mui/material';
import { colorsCode } from '../common/colors';

export const theme = {
    mui: createTheme({
        typography: {
            fontFamily: [
                '"Mckinsey Sans"',
                '-apple-system',
                'BlinkMacSystemFont',
                '"Oxygen"',
                '"Ubuntu"',
                '"Cantarell"',
                '"Fira Sans"',
                '"Droid Sans"',
                '"Helvetica Neue"',
                'sans-serif',
                '"Apple Color Emoji"',
                '"Segoe UI Emoji"',
                '"Segoe UI Symbol"',
            ].join(','),
        },
        palette: {
            primary: {
                main: colorsCode.PrimaryDeepBlue,
                contrastText: colorsCode.white,
            },
        },
        overrides: {},
    }),
};
