import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { DemographicsTableContainer, ColumnCell, DemographicsTableRow, ColumnHeaderRow } from './styles';
import Chip from '../../../components/KnowledgeCenter/Chip';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const DemographicsTable = ({ data }) => {
    return (
        <DemographicsTableContainer columns={data.columns.length}>
            <ColumnHeaderRow columns={data.columns.length}>
                {data.columns.map(column => {
                    return (
                        <ColumnCell key={column} gap="0">
                            <Text
                                fontColor={colorsCode.TextHeading}
                                fontSize={16}
                                lineHeight={24}
                                label={column}
                            ></Text>
                        </ColumnCell>
                    );
                })}
            </ColumnHeaderRow>
            {data.data.map(cellData => {
                return (
                    <DemographicsTableRow key={cellData.meaningful} columns={data.columns.length}>
                        <ColumnCell>
                            <Chip
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={500}
                                label={cellData.sample}
                                borderRadius="1.5px"
                                padding={`${pxToVh(4)}vh ${pxToVw(5)}vw`}
                            />
                        </ColumnCell>
                        <ColumnCell>
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                label={cellData.meaningful}
                            ></Text>
                        </ColumnCell>
                        <ColumnCell>
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                label={cellData.less_meaning}
                            ></Text>
                        </ColumnCell>
                    </DemographicsTableRow>
                );
            })}
        </DemographicsTableContainer>
    );
};

DemographicsTable.defaultProps = {
    data: {
        columns: [],
        data: [],
    },
};

DemographicsTable.propTypes = {
    data: PropTypes.shape({
        columns: PropTypes.arrayOf(PropTypes.string),
        data: PropTypes.arrayOf(PropTypes.shape({})),
    }),
};

export default DemographicsTable;
