import React, { useRef, useContext, useEffect } from 'react';
import Text from '../../../components/KnowledgeCenter/Text';
import { Section, FlexColumnWithAlign, FlexColumn } from '../../../common/styled/styled';
import AnalysisOverview from './AnalysisOverview';
import { ANALYSIS_TEXT, SECTION_KEYS } from '../../../utils/kc-constants';
import { ANALYSIS_OVERVIEW_DATA, OHIInsightFooter, OHIInsightData } from '../../../utils/kc-data';
import { AppContext } from '../Home';
import { useIntersection } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import OHIInsight from './OHIInsights';

const AnalysisPage = () => {
    const refs = useRef([]);
    const { setActiveSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);
    return (
        <Section gap={pxToVh(60)} padding={`${pxToVh(70)}vh ${pxToVw(20)}vw ${pxToVh(100)}vh ${pxToVw(59)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(16)}>
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={44}
                    lineHeight={52}
                    label={ANALYSIS_TEXT.ANALYSIS_HEADING}
                    fontWeight={500}
                />
                <Text
                    fontColor={colorsCode.Neutral80}
                    fontSize={24}
                    lineHeight={36}
                    label={ANALYSIS_TEXT.ANALYSIS_DESCRIPTION}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign
                ref={element => (refs.current[0] = element)}
                id={SECTION_KEYS.ANALYSIS_OVERVIEW}
                margin={`-${pxToVh(9)}vh 0 0 0`}
            >
                <AnalysisOverview overviewData={ANALYSIS_OVERVIEW_DATA} />
            </FlexColumnWithAlign>
            {/* <FlexColumnWithAlign ref={element => (refs.current[1] = element)} id={SECTION_KEYS.OHI_INSIGHTS}>
                <OHIInsights insightsData={OHI_INSIGHTS_DATA} />
            </FlexColumnWithAlign> */}
            <FlexColumn ref={element => (refs.current[1] = element)} id={SECTION_KEYS.OHI_INSIGHTS}>
                <OHIInsight data={OHIInsightData} footerData={OHIInsightFooter}></OHIInsight>
            </FlexColumn>
        </Section>
    );
};

AnalysisPage.defaultProps = {};

AnalysisPage.propTypes = {};

export default AnalysisPage;
