import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import TextWithFootNote from '../../../components/KnowledgeCenter/TextWithFootNote';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { ImageContainer, Image, ParentDiv } from '../OverviewPage/styles';
import { PhaseContentContainer, WarningInfoContainer } from '../SetupSurveyPage/styles';
import ContentWithFootNotes from './ContentWithFootNote';
import { LIVE_SURVEY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const SurveyActivityContent = ({ heading, activityData }) => {
    return (
        <FlexColumnWithAlign gap={pxToVh(38)} className="full-width">
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={heading}
                fontWeight={500}
            />
            <PhaseContentContainer gap={pxToVh(40)}>
                {activityData.options.map((data, index) => {
                    const isEven = (index + 1) % 2 === 0;
                    return (
                        <ParentDiv
                            alignItems="flex-start"
                            key={data.key}
                            marginLeft="0px"
                            padding={`0 ${pxToVw(32)}vw 0 0`}
                        >
                            <FlexColumnWithAlign>
                                <FlexColumnWithAlign gap={pxToVh(18)}>
                                    <TextWithFootNote
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={24}
                                        lineHeight={28}
                                        label={data.heading.text}
                                        footNote={data.heading?.foot_note?.tooltip_text}
                                        footerLabel={data.heading?.foot_note?.label}
                                        fontWeight={500}
                                    ></TextWithFootNote>
                                    {data?.listing_data ? <ContentWithFootNotes data={data} /> : null}
                                </FlexColumnWithAlign>
                            </FlexColumnWithAlign>
                            <ImageContainer className={!isEven ? 'top-right' : null}>
                                <Image src={data.image_url} alt={`survey-launch-${index}`} className="img" />
                            </ImageContainer>
                        </ParentDiv>
                    );
                })}
                {activityData.considerations_text ? (
                    <WarningInfoContainer padding={`${pxToVh(13)}vh ${pxToVw(32)}vw ${pxToVh(34)}vh`}>
                        <FlexColumnWithAlign gap={pxToVh(4)}>
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={500}
                                label={LIVE_SURVEY_TEXT.CONSIDERATIONS_TEXT}
                            />
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                label={activityData.considerations_text}
                            />
                        </FlexColumnWithAlign>
                    </WarningInfoContainer>
                ) : null}
                {activityData?.activities ? (
                    <FlexColumnWithAlign gap={pxToVh(32)}>
                        <Text
                            fontColor={colorsCode.PrimaryDeepBlue}
                            fontSize={24}
                            lineHeight={28}
                            fontWeight={500}
                            label={activityData?.activities?.heading}
                        />
                        {activityData.activities.data.map((activity, index) => {
                            return (
                                <FlexColumnWithAlign key={`activity-${index}`} gap={pxToVh(15)}>
                                    <TextWithFootNote
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={16}
                                        lineHeight={24}
                                        label={activity.heading.text}
                                        footNote={activity.heading?.foot_note?.tooltip_text}
                                        footerLabel={activity.heading?.foot_note?.label}
                                        fontWeight={500}
                                    ></TextWithFootNote>
                                    {activity?.listing_data ? <ContentWithFootNotes data={activity} /> : null}
                                </FlexColumnWithAlign>
                            );
                        })}
                    </FlexColumnWithAlign>
                ) : null}
            </PhaseContentContainer>
        </FlexColumnWithAlign>
    );
};

SurveyActivityContent.defaultProps = {
    activityData: [],
    heading: '',
};

SurveyActivityContent.propTypes = {
    activityData: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({})),
        considerations_text: PropTypes.string,
        activities: PropTypes.shape({
            heading: PropTypes.string,
            data: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
    heading: PropTypes.string,
};

export default SurveyActivityContent;
