import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { Container, MediaContainer, Thumbnail } from './styles';
import { colorsCode, pxToVh } from '../../../common/colors';

const OverviewCard = ({ title, heading, text, image, padding, mediaHeight, backgroundColor, width, height }) => {
    return (
        <Container gap={pxToVh(35)} padding={padding} backgroundColor={backgroundColor} width={width} height={height}>
            <MediaContainer mediaHeight={mediaHeight}>
                <Thumbnail src={image} alt="thumbnail" />
            </MediaContainer>
            <FlexColumnWithAlign gap={pxToVh(32)}>
                <Text fontColor={colorsCode.Neutral80} fontSize={24} lineHeight={28} label={title} fontWeight={500} />
                <FlexColumnWithAlign gap={pxToVh(21)}>
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        label={heading}
                        fontWeight={500}
                    />
                    <Text fontColor={colorsCode.TextSubtle} fontSize={16} lineHeight={24} label={text} />
                </FlexColumnWithAlign>
            </FlexColumnWithAlign>
        </Container>
    );
};

OverviewCard.defaultProps = {
    heading: null,
    text: null,
    padding: null,
    image: null,
    mediaHeight: null,
    height: null,
    width: null,
    backgroundColor: null,
};

OverviewCard.propTypes = {
    title: PropTypes.string.isRequired,
    heading: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
    padding: PropTypes.string,
    mediaHeight: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default OverviewCard;
