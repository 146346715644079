import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import VideoModal from '../../../components/KnowledgeCenter/Modals/VideoModal';
import {
    FlexColumnWithAlign,
    StepLabelStyled,
    StepContentStyled,
    StepStyled,
    StepperStyled,
    StepConnectorStyled,
} from '../../../common/styled/styled';
import { ImageContainer } from '../OverviewPage/styles';
import {
    PhaseContentContainer,
    StepperContainer,
    StepIconContainer,
    StepContentContainer,
    ImagePlaceholder,
    ImageStyled,
} from './styles';
import placeholderImg from '../../../assets/img/img_placeholder-kc.svg';
import { SETUP_SURVEY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const Communications = ({ communicationData }) => {
    const [showImageModal, setShowImageModal] = useState({ show: false, url: null });

    return (
        <Fragment>
            <FlexColumnWithAlign gap={pxToVh(25)}>
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={36}
                    lineHeight={44}
                    label={SETUP_SURVEY_TEXT.COMMUNICATIONS_SECTION_HEADING}
                    fontWeight={500}
                />
                <PhaseContentContainer gap={pxToVh(25)}>
                    <Text
                        fontColor={colorsCode.TextSubtle}
                        fontSize={16}
                        lineHeight={24}
                        label={SETUP_SURVEY_TEXT.COMMUNICATIONS_SECTION_DESCRIPTION}
                        fontWeight={500}
                    />
                    <StepperContainer>
                        <StepConnectorStyled isFirst />
                        <StepperStyled orientation="vertical" connector={<StepConnectorStyled />}>
                            {communicationData.map((data, index) => {
                                return (
                                    <StepStyled key={data.key} active>
                                        <StepContentContainer key={data.key} alignItems="stretch" gap={pxToVw(126)}>
                                            <FlexColumnWithAlign>
                                                <StepLabelStyled
                                                    StepIconComponent={() => <StepIcon icon={data?.icon_url} />}
                                                    optional={
                                                        <Text
                                                            fontColor={colorsCode.Neutral80}
                                                            fontSize={14}
                                                            lineHeight={20}
                                                            label={data.caption}
                                                            fontWeight={300}
                                                            fontStyle="italic"
                                                        />
                                                    }
                                                >
                                                    <Text
                                                        fontColor={colorsCode.PrimaryDeepBlue}
                                                        fontSize={24}
                                                        lineHeight={29}
                                                        label={data.title}
                                                        fontWeight={300}
                                                    />
                                                </StepLabelStyled>
                                                <StepContentStyled>
                                                    <FlexColumnWithAlign gap={pxToVh(8)}>
                                                        <Text
                                                            fontColor={colorsCode.PrimaryDeepBlue}
                                                            fontSize={24}
                                                            lineHeight={28}
                                                            label={data.heading}
                                                            fontWeight={500}
                                                        />
                                                        <Text
                                                            fontColor={colorsCode.PrimaryDeepBlue}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            label={data.body}
                                                        />
                                                    </FlexColumnWithAlign>
                                                </StepContentStyled>
                                            </FlexColumnWithAlign>
                                            {data.image_url ? (
                                                <ImageContainer className="center">
                                                    <ImageStyled
                                                        src={data.image_url}
                                                        alt={`communication-${index}`}
                                                        onClick={() =>
                                                            setShowImageModal({ show: true, url: data.image_url })
                                                        }
                                                    />
                                                </ImageContainer>
                                            ) : (
                                                <ImagePlaceholder>
                                                    <img src={placeholderImg} alt="placeholder" />
                                                </ImagePlaceholder>
                                            )}
                                        </StepContentContainer>
                                    </StepStyled>
                                );
                            })}
                            <StepConnectorStyled isLast />
                        </StepperStyled>
                    </StepperContainer>
                </PhaseContentContainer>
            </FlexColumnWithAlign>
            <VideoModal
                open={showImageModal.show}
                url={showImageModal.url}
                onClose={() => setShowImageModal({ ...showImageModal, show: false })}
                isImage
            />
        </Fragment>
    );
};

const StepIcon = ({ icon }) => {
    return (
        <StepIconContainer>
            <img src={icon} alt="step-icon" />
        </StepIconContainer>
    );
};

StepIcon.propTypes = {
    icon: PropTypes.string.isRequired,
};

Communications.defaultProps = {
    communicationData: [],
};

Communications.propTypes = {
    communicationData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default Communications;
