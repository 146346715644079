import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { Container, MediaContainer, Thumbnail, PlayIcon, ChipStyled } from './styles';
import videoIcon from '../../../assets/img/video-play.svg';
import { MEDIA_TYPE, MEDIA_LABELS } from '../../../utils/kc-constants';
import { colorsCode, pxToVh } from '../../../common/colors';

const ContentCard = ({
    text,
    padding,
    mediaHeight,
    backgroundColor,
    mediaType,
    mediaLabel,
    width,
    height,
    onClick,
    data,
}) => {
    return (
        <Container
            gap={pxToVh(35)}
            padding={padding}
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            onClick={() => onClick(mediaType, data)}
        >
            <MediaContainer mediaHeight={mediaHeight}>
                <ChipStyled label={mediaLabel || MEDIA_LABELS[mediaType]} />
                <Thumbnail src={data?.thumbnail} alt="thumbnail" />
                {mediaType === MEDIA_TYPE.VIDEO ? <PlayIcon src={videoIcon} alt="video-icon" /> : null}
            </MediaContainer>
            <Text
                fontColor={colorsCode.Neutral80}
                fontSize={16}
                lineHeight={24}
                label={text}
                fontWeight={500}
                className="content-card-text"
                maxLines={3}
            />
        </Container>
    );
};

ContentCard.defaultProps = {
    mediaType: MEDIA_TYPE.ARTICLE,
    mediaLabel: null,
    padding: null,
    mediaHeight: null,
    height: null,
    width: null,
    backgroundColor: null,
    onClick: () => {},
    data: {},
};

ContentCard.propTypes = {
    text: PropTypes.string.isRequired,
    mediaType: PropTypes.oneOf(Object.values(MEDIA_TYPE)),
    mediaLabel: PropTypes.string,
    padding: PropTypes.string,
    mediaHeight: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    onClick: PropTypes.func,
    data: PropTypes.shape({
        thumbnail: PropTypes.string,
        url: PropTypes.string,
        text: PropTypes.string,
    }),
};

export default ContentCard;
