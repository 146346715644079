import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Button from '../Button';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { Container, Icon } from './styles';
import { ReactComponent as Forward2Icon } from '../../../assets/img/forward2.svg';
import { LABELS } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const PracticeCard = ({ heading, icon, padding, backgroundColor, width, height, onClick, data, buttonText }) => {
    return (
        <Container gap={pxToVw(18)} padding={padding} backgroundColor={backgroundColor} width={width} height={height}>
            <Icon src={icon} alt="card-icon" />
            <FlexColumnWithAlign gap={pxToVh(1)}>
                <Text
                    fontColor={colorsCode.PrimaryDeepBlue}
                    fontSize={24}
                    lineHeight={28}
                    label={heading}
                    fontWeight={500}
                />
                <Button
                    fontColor={colorsCode.SecondaryDeepBlue2}
                    fontSize={16}
                    lineHeight={24}
                    endIcon={<Forward2Icon className="double-right-icon" />}
                    variant="text"
                    label={buttonText || LABELS.LEARN_MORE_CTA}
                    isUppercase={false}
                    textDecoration="underline"
                    onClick={() => onClick(data)}
                    padding={0}
                />
            </FlexColumnWithAlign>
        </Container>
    );
};

PracticeCard.defaultProps = {
    subheading: null,
    icon: null,
    padding: null,
    height: null,
    width: null,
    backgroundColor: null,
    onClick: () => {},
    data: {},
    buttonText: null,
};

PracticeCard.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    icon: PropTypes.string,
    padding: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
    onClick: PropTypes.func,
    data: PropTypes.shape({}),
    buttonText: PropTypes.string,
};

export default PracticeCard;
