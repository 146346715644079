import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import {
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    FlexColumnWithAlign,
    FlexRow,
} from '../../../common/styled/styled';
import { AccordianContainer, KeyBox } from './styles';
import { PRESURVEY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { ExpandMore } from '@mui/icons-material';
import { alphaVal } from '../../../utils/functions';

const KeyActivities = ({ activitiesData }) => {
    return (
        <Fragment>
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={PRESURVEY_TEXT.KEY_ACTIVITIES_HEADING}
                fontWeight={500}
            />
            <AccordianContainer style={{ marginTop: '20px' }}>
                {activitiesData.map((data, index) => {
                    return (
                        <AccordionStyled
                            style={{ borderTop: '0px solid', borderBottom: '0px solid', noDoubleBorder: true }}
                            key={data.key}
                            // defaultExpanded={index === 0}
                        >
                            <AccordionSummaryStyled expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}>
                                <FlexRow gap={pxToVw(24)} alignItems="flex-start">
                                    <KeyBox style={{ height: 60, minWidth: 40, width: 60 }}>
                                        <Text
                                            fontColor={colorsCode.PrimaryDeepBlue}
                                            fontSize={36}
                                            lineHeight={26}
                                            label={alphaVal(index)}
                                            fontWeight={500}
                                        />
                                    </KeyBox>
                                    <FlexColumnWithAlign gap={pxToVh(4)}>
                                        <Text
                                            fontColor={colorsCode.PrimaryDeepBlue}
                                            fontSize={24}
                                            lineHeight={28}
                                            fontWeight={500}
                                            label={data.title}
                                        />
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={16}
                                            lineHeight={24}
                                            label={data.sub_title}
                                        />
                                    </FlexColumnWithAlign>
                                </FlexRow>
                            </AccordionSummaryStyled>
                            <AccordionDetailsStyled>
                                <FlexColumnWithAlign gap={pxToVh(4)}>
                                    <div style={{ marginLeft: '5vw' }}>
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={16}
                                            lineHeight={24}
                                            label={data.body}
                                        />
                                    </div>
                                </FlexColumnWithAlign>
                            </AccordionDetailsStyled>
                        </AccordionStyled>
                    );
                })}
            </AccordianContainer>
        </Fragment>
    );
};

KeyActivities.defaultProps = {
    activitiesData: [],
};

KeyActivities.propTypes = {
    activitiesData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default KeyActivities;
