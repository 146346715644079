import { createSlice } from '@reduxjs/toolkit';
import { SurveyTemplatesLang } from '../../actions/Surveys/action';

export const INITIAL_STATE = {
    loading: false,
    templatesLang: [],
    error: false,
    errorMessage: '',
};
const TemplateLanguages = createSlice({
    name: 'TemplateLanguages',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(SurveyTemplatesLang.pending, state => {
                state.loading = false;
            })
            .addCase(SurveyTemplatesLang.fulfilled, (state, { payload }) => {
                const { data = [] } = payload;
                const res = data.map(item => {
                    const { short_code = 'en', language_text = '' } = item;
                    return {
                        ...item,
                        name: language_text,
                        is_selected: short_code === 'en' ? true : false,
                        default: short_code === 'en' ? true : false,
                    };
                });
                state.loading = false;
                state.templatesLang = res;
            })
            .addCase(SurveyTemplatesLang.rejected, state => {
                state.loading = false;
                state.error = true;
            });
    },
});
export default TemplateLanguages.reducer;
