import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import Chip from '../Chip';
import { FlexBetween, FlexRow } from '../../../common/styled/styled';
import { Container, Grid, LegendContainer, LegendColorBox } from './styles';
import ActivitesColumn from './ActivitesColumn';
import TimeTable from './TimeTable';
import warningIcon from '../../../assets/img/warning.svg';
import { CONFIG, PRESURVEY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { downloadURL } from '../../../utils/functions';
import cloudDownload from '../../../assets/img/cloud-download.svg';
import Button from '../../../components/KnowledgeCenter/Button';

const GanttChart = ({ timelineActivities, timelineDurationData }) => {
    const [collapsedItems, setCollapsedItems] = useState([0, 1, 2, 3]);

    const getLegend = () => {
        const legend = timelineDurationData.reduce((result, item) => {
            if (!Object.keys(result).includes(item.person.key)) {
                result[item.person.key] = {
                    ...item.person,
                    color: item.color,
                };
            }
            return result;
        }, {});
        return (
            <LegendContainer gap={pxToVw(11)}>
                <FlexBetween className="full-width" alignItems="flex-start">
                    {Object.values(legend).map(legend => {
                        return (
                            <FlexRow key={legend.key} gap={pxToVw(11)}>
                                <LegendColorBox color={legend.color} />
                                <Text
                                    fontColor={colorsCode.TextHeading}
                                    fontSize={14}
                                    lineHeight={20}
                                    label={legend.value}
                                />
                            </FlexRow>
                        );
                    })}
                    <Button
                        fontSize={16}
                        lineHeight={24}
                        label={PRESURVEY_TEXT.DOWNLAD_DEEPLOYMENT_TIMELINE_TEXT}
                        startIcon={<img src={cloudDownload} alt="cloud-download" />}
                        padding={`${pxToVh(8)}vh ${pxToVw(16)}vw`}
                        isUppercase={false}
                        onClick={() =>
                            downloadURL(`${CONFIG.BASE_IMG_URL}/knowledge_center_MVP_content_gantt_chart.pptx`)
                        }
                    />
                </FlexBetween>
            </LegendContainer>
        );
    };

    return (
        <Container gap={pxToVh(12)}>
            {getLegend()}
            <Grid>
                <ActivitesColumn
                    activities={timelineActivities}
                    collapsedItems={collapsedItems}
                    setCollapsedItems={setCollapsedItems}
                />
                <TimeTable
                    activities={timelineActivities}
                    activitiesDuration={timelineDurationData}
                    collapsedItems={collapsedItems}
                />
            </Grid>
            <Chip
                lineHeight={20}
                fontColor={colorsCode.Neutral80}
                borderRadius="12px"
                label={PRESURVEY_TEXT.TIMELINE_WARNING_TEXT}
                className="timeline-chip"
                icon={<img src={warningIcon} alt="warning" />}
                padding={`${pxToVh(14)}vh ${pxToVw(32)}vw`}
            />
        </Container>
    );
};

GanttChart.defaultProps = {
    timelineActivities: [],
    timelineDurationData: [],
};

GanttChart.propTypes = {
    timelineActivities: PropTypes.arrayOf(PropTypes.shape({})),
    timelineDurationData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default GanttChart;
