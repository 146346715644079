import chatIcon from '../assets/img/chat.svg';
import helpIcon from '../assets/img/help_double_chat.svg';
import { PATHS } from '../global-constants';
// eslint-disable-next-line no-undef
const ENV = process.env.REACT_APP_ENVIRONMENT;

let BASE_IMG_URL = 'https://dm9y3gm31r74.cloudfront.net/static/knowledge_centre';

if (ENV === 'dev') {
    BASE_IMG_URL = 'https://dm9y3gm31r74.cloudfront.net/static/knowledge_centre';
}

if (ENV === 'stg') {
    BASE_IMG_URL = 'https://d3c5ezcryx3jf5.cloudfront.net/static/knowledge_centre';
}

if (ENV === 'prod') {
    BASE_IMG_URL = 'https://d15pt2hucraamo.cloudfront.net/static/knowledge_centre';
}

export const CONFIG = {
    BASE_IMG_URL,
};

export const LABELS = {
    LEARN_MORE_CTA: 'Learn More',
    DOWNLOAD_CTA: 'Download',
    DOWNLOAD_CHECKLIST_CTA: 'Download the checklist',
    OBJECTIVE: 'Objectives:',
    OUTPUT: 'Outputs',
};

export const EXCLUDE_VERSIONS = ['lte'];

export const LANDING_PAGE_TEXT = {
    WELCOME_HEADING: 'Welcome to the OHI Knowledge Centre!',
    WELCOME_SUBHEADING: 'Here you can find material and resources for navigating your OHI journey.',
    TOUR_HEADING: 'New to <span style="font-weight:400;">Organizational Health Index</span> platform?',
    TAKE_TOUR_CTA: 'Take a tour...',
    TOUR_TAKEN_CTA: `I've already taken the tour`,
    INTRO_CHIP: 'Introduction',
    UNDERSTAND_PLATFORM: 'Understand the platform',
    SETUP_SURVEY: 'Need help with setting up a survey?',
    UNDERSTAND_RESULT_ANALYSIS: 'Understand the Results Analysis process',
    EXPLORE_MORE_CTA: 'Explore More',
};

export const ORGHEALTH_OVERVIEW_TEXT = {
    ORGHEALTH_SECTION_HEADING: 'What is OrgHealth?',
    OHI_SECTION_HEADING: 'What is Organizational Health Index?',
    OHI_SURVEY_DESCRIPTION:
        'The OHI survey provides a detailed picture of the Health and internal network dynamics of an organization',
    OHI_PRACTICES_HEADING:
        'Practices are behaviors - “<span style="color:#0679C3;">what you do</span>” - to drive each outcome',
    OHI_PRACTICES_DESCRIPTION: 'Measures frequency, from “almost always” to “almost never”',
    OHI_PRACTICES_OUTCOMES: '37 Management practices measured under 9 outcomes',
    OHI_PRACTICES_OUTCOMES_4: '43 Management practices measured under 9 outcomes',
};

export const PRESURVEY_TEXT = {
    PRESURVEY_HEADING: 'Pre-survey requirements',
    KEY_ACTIVITIES_HEADING: 'Key activities before survey setup',
    DEPLOYMENT_SECTION_HEADING: 'Deployment timeline',
    DEPLOYMENT_SECTION_DESCRIPTION:
        'The end to end OHI survey process typically takes <span style="font-weight:500;">4-6 weeks</span>.',
    LEGAL_CONSENT_HEADING: 'Client consent REQUIRED ONLY if sensitive demographic information is collected',
    TIMELINE_WARNING_TEXT: 'Timelines should be finalized in collaboration with SDM',
    DOWNLAD_DEEPLOYMENT_TIMELINE_TEXT: 'Download deployment timeline',
};

export const SETUP_SURVEY_TEXT = {
    SETUP_SURVEY_HEADING: 'Phase I - Setup Survey',
    SETUP_SURVEY_DESCRIPTION:
        'The setup phase ensures that the survey is designed properly to meet the client’s needs and mitigate any risks or delays.',
    OVERVIEW_SECTION_HEADING: 'Survey logistics',
    ADMINISTRATION_PLAN_HEADING: 'Survey logistics',
    TASK_CHECKLIST_HEADING: 'Task checklist (summary of activities)',
    TIME_SENSITIVE_TEXT: 'Time intensive',
    COMPONENTS_SECTION_HEADING: 'Survey Components & Design',
    COMMUNICATIONS_SECTION_HEADING: 'Communications',
    COMMUNICATIONS_SECTION_DESCRIPTION: 'For Primary Client Contact to own',
    IT_TESTING_SECTION_HEADING: 'IT Testing and Safelisting',
    IT_STEP_ACTIONS_TEXT: 'Step by step actions',
    SEE_NEXT_LABEL: 'What you should see next',
    LEGAL_CONSENT_HEADING: 'Sensitive demographics consideration',
    LEGAL_CONSENT_SUB_HEADING:
        'To understand organization\'s approach towards hybrid model, we recommend selecting additional section called "Workplace flexibility" - 3 demographic questions to understand how an organization operates.',
    OPTIONAL_QUESTIONS_HEADING: 'Optional questions',
    QUESTIONS_HEADING: 'Questions',
};

export const LIVE_SURVEY_TEXT = {
    SETUP_SURVEY_HEADING: 'Phase II - Survey Live',
    SETUP_SURVEY_DESCRIPTION:
        'Driving a strong survey participation rate is the primary focus during the survey live phase',
    OVERVIEW_SECT_HEADING: 'Survey live overview',
    TASK_CHECKLIST_HEADING: 'Task checklist (summary of activities)',
    SURVEY_LAUNCH_SECT_HEADING: 'Survey Launch Activity',
    COMMUNICATION_ACTIVITY_SECT_HEADING: 'Communication Activity',
    RESPONSE_RATE_ACTIVITY_SECT_HEADING: 'Response Rate Activity',
    ANALYSIS_ACTIVITY_SECT_HEADING: 'Analysis preparation and close survey deep dive',
    CONSIDERATIONS_TEXT: 'Considerations',
};

export const ANALYSIS_TEXT = {
    ANALYSIS_HEADING: 'Phase III - Analysis',
    ANALYSIS_DESCRIPTION: 'The OHI report provides different lenses through which to understand organizational health',
    OVERVIEW_SECT_HEADING: 'Analysis overview',
    OHI_INSIGHTS_SECT_HEADING: 'Organizational Health Index Insights',
};

export const GLOSSARY_TEXT = {
    GLOSSARY_HEADING: 'Glossary',
    GLOSSARY_DESCRIPTION: 'The OHI report provides different lenses through which to understand organizational health',
    OUTCOME_SECT_HEADING: 'Outcome & Practices',
    OUTCOME_SECT_DESCRIPTION: 'Outcomes with associated practices',
    PRACTICES_TEXT: 'Practices',
};

export const NAVBAR_OPTIONS = [
    {
        label: 'OrgHealth Overview',
        key: 'orghealth_overview',
        options: [
            {
                label: 'What is OrgHealth?',
                key: 'orghealth',
            },
            {
                label: 'What is OHI?',
                key: 'ohi',
            },
        ],
    },
    {
        label: 'Pre-Survey Requirements',
        key: 'pre-survey',
        options: [
            {
                label: 'Key Activities',
                key: 'key_activities',
            },
            {
                label: 'Deployement Timeline',
                key: 'deployment_timeline',
            },
        ],
    },
    {
        label: 'Setup Survey',
        key: 'setup_survey',
        options: [
            {
                label: 'Survey setup overview',
                key: 'survey_setup_overview',
            },
            {
                label: 'Survey components & design',
                key: 'survey_components_design',
            },
            {
                label: 'Communication',
                key: 'communication',
            },
            {
                label: 'IT testing and safelisting',
                key: 'it_testing_safelisting',
            },
        ],
    },
    {
        label: 'Setup Live',
        key: 'live_survey',
        options: [
            {
                label: 'Survey live overview',
                key: 'survey_live_overview',
            },
            {
                label: 'Survey launch activity',
                key: 'survey_launch_activity',
            },
            {
                label: 'Communication activity',
                key: 'communication_activity',
            },
            {
                label: 'Response activity',
                key: 'response_activity',
            },
            {
                label: 'Analysis preparation and close survey deep dive',
                key: 'analysis_close_survey',
            },
        ],
    },
    {
        label: 'Analysis',
        key: 'analysis',
        options: [
            {
                label: 'Analysis overview',
                key: 'analysis_overview',
            },
            {
                label: 'How do we develop OHI insights?',
                key: 'ohi_insights',
            },
        ],
    },
    {
        label: 'Glossary',
        key: 'glossary',
        options: [
            {
                label: 'Outcomes & practices',
                key: 'outcomes_practices',
            },
            {
                label: 'Additional questions',
                key: 'additional_questions',
            },
        ],
    },
];

export const NAVBAR_ROUTE_TO_KEYS = {
    [PATHS.KC_OVERVIEW]: '1',
    [PATHS.KC_PRESURVEY]: '2',
    [PATHS.KC_SETUP_SURVEY]: '3',
    [PATHS.KC_LIVE_SURVEY]: '4',
    [PATHS.KC_ANALYSIS]: '5',
    [PATHS.KC_GLOSSARY]: '6',
    [PATHS.KNOWLEDGE_CENTER_HOME]: '24',
};

export const NAVBAR_KEYS_TO_ROUTES = {
    1: PATHS.KC_OVERVIEW,
    2: PATHS.KC_PRESURVEY,
    3: PATHS.KC_SETUP_SURVEY,
    4: PATHS.KC_LIVE_SURVEY,
    5: PATHS.KC_ANALYSIS,
    6: PATHS.KC_GLOSSARY,
    24: PATHS.KNOWLEDGE_CENTER_HOME,
};

export const SECTION_KEYS = {
    ORGHEALTH: '7',
    OHI: '8',
    KEY_ACTIVITES: '9',
    DEPLOYMENT_TIMELINE: '10',
    SETUP_SURVEY_LOGISTICS: '11',
    SETUP_SURVEY_COMPONENTS_DESIGN: '12',
    SETUP_SURVEY_COMMUNICATION: '13',
    SETUP_SURVEY_IT_TESTING: '22',
    LIVE_SURVEY_OVERVIEW: '14',
    SURVEY_LAUNCH_ACTIVITY: '15',
    COMMUNICATION_ACTIVITY: '16',
    RESPONSE_ACTIVITY: '17',
    ANALYSIS_PREPARATION_SURVEY_CLOSE: '18',
    ANALYSIS_OVERVIEW: '19',
    OHI_INSIGHTS: '20',
    GLOSSARY_OUTCOMES: '21',
    ADDITIONAL_SECTIONS: '23',
};

export const SECTION_KEYS_TO_ROUTES = {
    7: 'ORGHEALTH',
    8: 'OHI',
    9: 'KEY_ACTIVITES',
    10: 'DEPLOYMENT_TIMELINE',
    11: 'SETUP_SURVEY_LOGISTICS',
    12: 'SETUP_SURVEY_COMPONENTS_DESIGN',
    13: 'SETUP_SURVEY_COMMUNICATION',
    22: 'SETUP_SURVEY_IT_TESTING',
    14: 'LIVE_SURVEY_OVERVIEW',
    15: 'SURVEY_LAUNCH_ACTIVITY',
    16: 'COMMUNICATION_ACTIVITY',
    17: 'RESPONSE_ACTIVITY',
    18: 'ANALYSIS_PREPARATION_SURVEY_CLOSE',
    19: 'ANALYSIS_OVERVIEW',
    20: 'OHI_INSIGHTS',
    21: 'GLOSSARY_OUTCOMES',
    23: 'ADDITIONAL_SECTIONS',
};

export const FOOTER_OPTIONS = [
    {
        label: 'Need more help?',
        sub_label: 'Write to us we will be happy to help you',
        icon: helpIcon,
    },
    {
        label: 'Share your feedback',
        sub_label: 'Have feedback to share on the resource library or other areas of the OHI portal?',
        icon: chatIcon,
    },
];

export const MEDIA_TYPE = {
    VIDEO: 'VIDEO',
    ARTICLE: 'ARTICLE',
    DOWNLOADABLE: 'DOWNLOADABLE',
};

export const MEDIA_LABELS = {
    VIDEO: 'Video',
    ARTICLE: 'Article',
    DOWNLOADABLE: 'Download',
};

export const LIST_TYPE = {
    NUMERAL: 'numeral',
    BULLET: 'bullet',
    NONE: 'none',
};

export const KC_VERSION_KEYS = {
    VERSION: 'knowledge_centre_version',
    FOUR: '4.0',
};
