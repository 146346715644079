import React from 'react';
import { Box, Typography } from '@mui/material';

const E404 = () => {
    return (
        <Box left="0" position="absolute" margin="0" right="0" sx={{ top: '20vh' }} data-testid="404">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="500">
                <Typography fontWeight="500" color="black" fontSize={28}>
                    {'Mckinsey & Company'}
                </Typography>
                <br />
                <Typography fontWeight="500" color="red" fontSize={23} variant="caption">
                    404 NOT FOUND
                </Typography>
                <br />
                <Typography fontWeight="500" color="#2B5580" fontSize={29}>
                    It seems like you&apos;re accessing wrong url.
                    <br /> Kindly ensure and revisit the page again.
                </Typography>
            </Box>
        </Box>
    );
};

export default E404;
