import React, { Fragment, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Text from '../../../components/KnowledgeCenter/Text';
import VideoModal from '../../../components/KnowledgeCenter/Modals/VideoModal';
import { FlexColumnWithAlign, FlexColumnCenter, Section } from '../../../common/styled/styled';
import { BackgroundImage } from './styles';
import ContentCardList from './ContentCardList';
import IntroComponent from './IntroComponent';
import overviewBackgroud from '../../../assets/img/overview-background.png';
import { KC_VERSION_KEYS, LANDING_PAGE_TEXT, MEDIA_TYPE, NAVBAR_KEYS_TO_ROUTES } from '../../../utils/kc-constants';
import { LANDING_PAGE_DATA, APP_TOUR_DATA, LANDING_PAGE_DATA_4, APP_TOUR_DATA_4 } from '../../../utils/kc-data';
import { colorsCode, pxToVh } from '../../../common/colors';

const LandingPage = () => {
    const history = useNavigate();
    const location = useLocation();
    const [showVideoModal, setShowVideoModal] = useState({ show: false, url: null });
    const [hasAppTourTaken, setHasAppTourTaken] = useState(false);
    const appTourStatus = localStorage.getItem('app_tour_status');
    const { search } = location;
    const params = new URLSearchParams(search);
    const [isVersionFour, setVersion] = useState(false);

    useEffect(() => {
        params.delete('section');
        history(
            {
                search: params.toString(),
            },
            { replace: true }
        );
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, [isVersionFour, setVersion]);

    useEffect(() => {
        if (appTourStatus === 'true') {
            setHasAppTourTaken(true);
        } else {
            setHasAppTourTaken(false);
        }
    }, [appTourStatus]);

    const onClickCard = (mediaType, card) => {
        if (mediaType === MEDIA_TYPE.VIDEO) {
            setShowVideoModal({ show: true, url: card?.url });
        } else if ((mediaType === MEDIA_TYPE.DOWNLOADABLE || mediaType === MEDIA_TYPE.ARTICLE) && card?.url) {
            const link = document.createElement('a');
            link.download = card?.file_name;
            link.href = card?.url;
            if (mediaType === MEDIA_TYPE.ARTICLE) {
                link.target = '_blank';
            }
            link.click();
        }
    };

    const onUpdateTourStatus = (value = true) => {
        localStorage.setItem('app_tour_status', value);
        setHasAppTourTaken(true);
    };

    const onExploreMore = route => {
        window.scrollTo(0, 0);
        params.delete('section');
        history({
            pathname: NAVBAR_KEYS_TO_ROUTES[route],
            search: params.toString(),
        });
    };

    return (
        <Fragment>
            <Section>
                <BackgroundImage src={overviewBackgroud} alt="overview-background" />
                <FlexColumnCenter gap={pxToVh(8)} margin={`0 0 ${pxToVh(132)}vh`}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={44}
                        lineHeight={52}
                        fontWeight={500}
                        label={LANDING_PAGE_TEXT.WELCOME_HEADING}
                        textAlign="center"
                    />
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={18}
                        lineHeight={28}
                        label={LANDING_PAGE_TEXT.WELCOME_SUBHEADING}
                        textAlign="center"
                    />
                </FlexColumnCenter>
                {!hasAppTourTaken ? (
                    <IntroComponent
                        data={isVersionFour ? APP_TOUR_DATA_4 : APP_TOUR_DATA}
                        onUpdateTourStatus={onUpdateTourStatus}
                        onClickVideo={url =>
                            setShowVideoModal({
                                show: true,
                                url: url,
                            })
                        }
                    />
                ) : null}
                {isVersionFour ? (
                    <FlexColumnWithAlign gap={pxToVh(46)}>
                        {LANDING_PAGE_DATA_4.map(section => {
                            return (
                                <ContentCardList
                                    key={section.key}
                                    data={section.options}
                                    heading={section.name}
                                    onClickCard={onClickCard}
                                    onClickMore={() => onExploreMore(section.route_to)}
                                />
                            );
                        })}
                    </FlexColumnWithAlign>
                ) : (
                    <FlexColumnWithAlign gap={pxToVh(46)}>
                        {LANDING_PAGE_DATA.map(section => {
                            return (
                                <ContentCardList
                                    key={section.key}
                                    data={section.options}
                                    heading={section.name}
                                    onClickCard={onClickCard}
                                    onClickMore={() => onExploreMore(section.route_to)}
                                />
                            );
                        })}
                    </FlexColumnWithAlign>
                )}
            </Section>
            <VideoModal
                open={showVideoModal.show}
                url={showVideoModal.url}
                onClose={() => setShowVideoModal({ show: false, url: null })}
            />
        </Fragment>
    );
};

LandingPage.defaultProps = {};

LandingPage.propTypes = {};

export default LandingPage;
