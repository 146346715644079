import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import Tooltip from './Tooltip';
import { FootNote } from '../../common/styled/styled';
import { colorsCode } from '../../common/colors';

const TextWithFootNote = ({
    fontColor,
    fontSize,
    lineHeight,
    fontWeight,
    letterSpacing,
    fontFamily,
    label,
    textAlign,
    component,
    children,
    onClick,
    className,
    href,
    textDecoration,
    fontStyle,
    maxLines,
    cursor,
    footerLabel,
    footNote,
}) => {
    return (
        <Text
            fontWeight={fontWeight}
            lineHeight={lineHeight}
            fontSize={fontSize}
            fontColor={fontColor}
            letterSpacing={letterSpacing}
            fontFamily={fontFamily}
            component={component}
            textAlign={textAlign}
            onClick={onClick}
            className={className}
            href={href}
            textDecoration={textDecoration}
            fontStyle={fontStyle}
            maxLines={maxLines}
        >
            {label}
            {footNote && (
                <Tooltip text={footNote}>
                    <FootNote>
                        <Text
                            fontColor={colorsCode.SecondaryDeepBlue500}
                            fontSize={12}
                            lineHeight={14}
                            fontWeight={300}
                            label={footerLabel}
                            cursor={cursor || 'inherit'}
                        />
                    </FootNote>
                </Tooltip>
            )}
            {children}
        </Text>
    );
};

TextWithFootNote.defaultProps = {
    fontColor: '',
    fontSize: null,
    lineHeight: null,
    fontWeight: null,
    letterSpacing: null,
    fontFamily: null,
    component: 'span',
    children: null,
    textAlign: null,
    label: null,
    onClick: null,
    className: null,
    href: null,
    textDecoration: null,
    fontStyle: null,
    maxLines: null,
    cursor: null,
    footerLabel: null,
    footNote: null,
};

TextWithFootNote.propTypes = {
    label: PropTypes.string,
    fontColor: PropTypes.string,
    fontSize: PropTypes.number,
    lineHeight: PropTypes.number,
    fontWeight: PropTypes.number,
    letterSpacing: PropTypes.string,
    component: PropTypes.string,
    fontFamily: PropTypes.string,
    children: PropTypes.node,
    textAlign: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    href: PropTypes.string,
    textDecoration: PropTypes.string,
    fontStyle: PropTypes.string,
    cursor: PropTypes.string,
    maxLines: PropTypes.number,
    footerLabel: PropTypes.string,
    footNote: PropTypes.string,
};

export default TextWithFootNote;
