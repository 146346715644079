import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { OrgHealthContentContainer, ImageContainer, Image, ParentDiv } from './styles';
import { colorsCode, pxToVh } from '../../../common/colors';

const OrgHealthContent = ({ data }) => {
    return (
        <OrgHealthContentContainer gap={pxToVh(58)}>
            {data.map((row, index) => {
                return (
                    <ParentDiv key={row.id} alignItems="flex-start">
                        <FlexColumnWithAlign gap={pxToVh(29)} margin={`${pxToVh(37)}vh 0 0 0`}>
                            <Text
                                fontColor={colorsCode.PrimaryDeepBlue}
                                fontSize={36}
                                lineHeight={44}
                                fontWeight={500}
                                label={row.heading}
                            />
                            <Text fontColor={colorsCode.Neutral80} fontSize={16} lineHeight={24} label={row.body} />
                        </FlexColumnWithAlign>
                        <ImageContainer className="center">
                            <Image src={row.image_url} alt={`org-health-${index}`} />
                        </ImageContainer>
                    </ParentDiv>
                );
            })}
        </OrgHealthContentContainer>
    );
};

OrgHealthContent.defaultProps = {
    data: [],
};

OrgHealthContent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OrgHealthContent;
