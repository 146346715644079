import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexBetween, FlexColumnWithAlign } from '../../../common/styled/styled';
import cloudDownload from '../../../assets/img/cloud-download.svg';
import { CONFIG, LABELS, SETUP_SURVEY_TEXT } from '../../../utils/kc-constants';
import Button from '../../../components/KnowledgeCenter/Button';
import { downloadURL } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const ITTesting = ({ infoData, definitionsData, stepActions }) => {
    console.log(stepActions, definitionsData, infoData);
    return (
        <FlexColumnWithAlign gap={pxToVh(32)} style={{ width: '100%' }}>
            <FlexBetween className="full-width" alignItems="flex-start">
                <Text
                    fontColor={colorsCode.PrimaryDeepBlue}
                    fontSize={36}
                    lineHeight={44}
                    label={SETUP_SURVEY_TEXT.IT_TESTING_SECTION_HEADING}
                    fontWeight={500}
                />
                <Button
                    fontSize={16}
                    lineHeight={24}
                    label={LABELS.DOWNLOAD_CTA}
                    startIcon={<img src={cloudDownload} alt="cloud-download" />}
                    padding={`${pxToVh(8)}vh ${pxToVw(16)}vw`}
                    isUppercase={false}
                    onClick={() =>
                        downloadURL(`${CONFIG.BASE_IMG_URL}/Phase_I_Setup Survey_IT_Testing_and_Safelisting.pptx`)
                    }
                />
            </FlexBetween>
            <FlexColumnWithAlign gap={pxToVh(32)}>
                <Text
                    fontColor={colorsCode.Neutral80}
                    fontSize={22}
                    lineHeight={24}
                    label={infoData.heading}
                    fontWeight={300}
                />
            </FlexColumnWithAlign>
        </FlexColumnWithAlign>
    );
};

ITTesting.defaultProps = {
    infoData: {},
    definitionsData: [],
    stepActions: {},
};

ITTesting.propTypes = {
    infoData: PropTypes.shape({
        heading: PropTypes.string,
        details: PropTypes.arrayOf(PropTypes.string),
        warning_text: PropTypes.string,
    }),
    definitionsData: PropTypes.arrayOf(PropTypes.shape({})),
    stepActions: PropTypes.shape({
        steps: PropTypes.arrayOf(PropTypes.shape({})),
        contact_info: PropTypes.shape({
            heading: PropTypes.string,
            details: PropTypes.arrayOf(PropTypes.string),
        }),
    }),
};

export default ITTesting;
