import React from 'react';
import PropTypes from 'prop-types';
// import { ExpandMore } from '@mui/icons-material';
import Text from '../../../components/KnowledgeCenter/Text';
import {
    // AccordionDetailsStyled,
    // AccordionStyled,
    // AccordionSummaryStyled,
    FlexBetween,
    FlexColumnWithAlign,
    // FlexRow,
} from '../../../common/styled/styled';
import { ImageContainer, Image } from '../OverviewPage/styles';
import {
    PhaseContentContainer,
    AdministrationPlanContainer,
    AdministrationContent,
    AdministrationSubheading,
    TaskChecklistContainer,
    // ChecklistItem,
    // TimeSensitiveColorBox,
} from './styles';
// import TimelineAccordian from '../PreSurveyPage/TimelineAccordian';
import cloudDownload from '../../../assets/img/cloud-download.svg';
// import checkIcon from '../../../assets/img/marked.svg';
import { CONFIG, LABELS, SETUP_SURVEY_TEXT } from '../../../utils/kc-constants';
import Chip from '../../../components/KnowledgeCenter/Chip';
import Button from '../../../components/KnowledgeCenter/Button';
import { downloadURL } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const SurveySetupOverview = ({ overviewData, administrationData, taskChecklistData }) => {
    console.log(overviewData, taskChecklistData);
    return (
        <FlexColumnWithAlign className="full-width">
            {/* <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={SETUP_SURVEY_TEXT.OVERVIEW_SECTION_HEADING}
                fontWeight={500}
            /> */}
            {/* <FlexColumnWithAlign gap={pxToVh(12)} margin={`${pxToVh(24)}vh 0 0 0`} className="full-width">
                <PhaseContentContainer>
                    <TimelineAccordian data={overviewData} isEven={false} showPhaseDivider={false} />
                </PhaseContentContainer>
                {overviewData?.note_text ? (
                    <Text
                        fontColor={colorsCode.TextSubtle}
                        fontSize={16}
                        lineHeight={24}
                        label={overviewData?.note_text}
                        fontWeight={300}
                        className="note-text"
                    />
                ) : null}
            </FlexColumnWithAlign> */}
            <AdministrationPlanContainer style={{ margin: 0 }}>
                <Text
                    fontColor={colorsCode.PrimaryDeepBlue}
                    fontSize={36}
                    lineHeight={44}
                    label={SETUP_SURVEY_TEXT.ADMINISTRATION_PLAN_HEADING}
                    fontWeight={500}
                />
                <FlexColumnWithAlign gap={pxToVh(2)} margin={`${pxToVh(29)}vh 0 0 0`}>
                    <PhaseContentContainer gap={pxToVh(40)}>
                        {administrationData?.options.map((data, index) => {
                            const isEven = (index + 1) % 2 === 0;
                            return (
                                <AdministrationContent key={data.key}>
                                    <FlexColumnWithAlign gap={pxToVh(18)}>
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={24}
                                            lineHeight={28}
                                            label={data.heading}
                                            fontWeight={500}
                                        />
                                        <FlexColumnWithAlign gap={pxToVh(12)}>
                                            <AdministrationSubheading className="full-width">
                                                <Text
                                                    fontColor={colorsCode.TextHeading}
                                                    fontSize={16}
                                                    lineHeight={24}
                                                    label={data.subheading}
                                                    fontWeight={500}
                                                />
                                            </AdministrationSubheading>
                                            <Text
                                                fontColor={colorsCode.TextSubtle}
                                                fontSize={16}
                                                lineHeight={24}
                                                label={data.body}
                                            />
                                            {isEven && data.child && (
                                                <>
                                                    <AdministrationSubheading className="full-width">
                                                        <Text
                                                            fontColor={colorsCode.TextHeading}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            label={data.child.subheading}
                                                            fontWeight={500}
                                                        />
                                                    </AdministrationSubheading>
                                                    <Text
                                                        fontColor={colorsCode.TextSubtle}
                                                        fontSize={16}
                                                        lineHeight={24}
                                                        label={data.child.body}
                                                    />
                                                </>
                                            )}
                                        </FlexColumnWithAlign>
                                    </FlexColumnWithAlign>
                                    <ImageContainer className={!isEven ? 'center-right' : null}>
                                        <Image src={data.image_url} alt={`administration-plan-${index}`} />
                                    </ImageContainer>
                                </AdministrationContent>
                            );
                        })}
                    </PhaseContentContainer>
                    {administrationData?.note_text ? (
                        <Chip
                            backgroundColor={colorsCode.Neutral05}
                            fontColor={colorsCode.TextSubtle}
                            fontSize={16}
                            lineHeight={24}
                            label={administrationData?.note_text}
                            padding={`${pxToVh(10)}vh ${pxToVw(20)}vw ${pxToVh(10)}vh  ${pxToVw(33)}vw`}
                            multiline
                            borderRadius="8px"
                        />
                    ) : null}
                </FlexColumnWithAlign>
            </AdministrationPlanContainer>
            <TaskChecklistContainer>
                <FlexBetween className="full-width" alignItems="flex-start">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={24}
                        lineHeight={28}
                        label={SETUP_SURVEY_TEXT.TASK_CHECKLIST_HEADING}
                        fontWeight={500}
                    />
                    <Button
                        fontSize={16}
                        lineHeight={24}
                        label={LABELS.DOWNLOAD_CHECKLIST_CTA}
                        startIcon={<img src={cloudDownload} alt="cloud-download" />}
                        padding={`${pxToVh(8)}vh ${pxToVw(16)}vw`}
                        isUppercase={false}
                        onClick={() =>
                            downloadURL(
                                `${CONFIG.BASE_IMG_URL}/Phase_I_Setup Survey_Task_checklist_summary_of_activities.pptx`
                            )
                        }
                    />
                </FlexBetween>
                {/* <FlexColumnWithAlign gap={pxToVh(16)} margin={`${pxToVh(20)}vh 0 0 0`}>
                    <PhaseContentContainer>
                        {taskChecklistData?.options?.map((data, index) => {
                            return (
                                <AccordionStyled key={data.key} defaultExpanded={index === 0}>
                                    <AccordionSummaryStyled
                                        expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                    >
                                        <Text
                                            fontColor={colorsCode.TextHeading}
                                            fontSize={16}
                                            lineHeight={24}
                                            label={data.heading}
                                            fontWeight={500}
                                        />
                                    </AccordionSummaryStyled>
                                    <AccordionDetailsStyled>
                                        <FlexColumnWithAlign gap={pxToVh(12)}>
                                            {data?.steps?.map((step, index) => {
                                                return (
                                                    <ChecklistItem
                                                        key={`step_${index}`}
                                                        gap={pxToVw(12)}
                                                        alignItems="flex-start"
                                                        isTimeIntensive={step.is_time_intensive}
                                                        className="full-width"
                                                    >
                                                        <img src={checkIcon} alt="check" />
                                                        <Text
                                                            fontColor={colorsCode.Neutral80}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            label={step.text}
                                                        />
                                                    </ChecklistItem>
                                                );
                                            })}
                                        </FlexColumnWithAlign>
                                    </AccordionDetailsStyled>
                                </AccordionStyled>
                            );
                        })}
                    </PhaseContentContainer>
                    <FlexRow gap={pxToVw(15)} margin={`${pxToVh(2)}vh 0 0 0`}>
                        <TimeSensitiveColorBox />
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize={16}
                            lineHeight={24}
                            label={SETUP_SURVEY_TEXT.TIME_SENSITIVE_TEXT}
                        />
                    </FlexRow>
                    {taskChecklistData?.note_text ? (
                        <Text
                            fontColor={colorsCode.TextSubtle}
                            fontSize={16}
                            lineHeight={24}
                            label={taskChecklistData?.note_text}
                            fontWeight={400}
                            className="note-text"
                        />
                    ) : null}
                </FlexColumnWithAlign> */}
            </TaskChecklistContainer>
        </FlexColumnWithAlign>
    );
};

SurveySetupOverview.defaultProps = {
    overviewData: {},
    administrationData: {},
    taskChecklistData: {},
};

SurveySetupOverview.propTypes = {
    overviewData: PropTypes.shape({
        note_text: PropTypes.string,
    }),
    administrationData: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({})),
        note_text: PropTypes.string,
    }),
    taskChecklistData: PropTypes.shape({
        options: PropTypes.arrayOf(PropTypes.shape({})),
        note_text: PropTypes.string,
    }),
};

export default SurveySetupOverview;
