import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { PhaseContentContainer } from '../SetupSurveyPage/styles';
import TimelineAccordian from '../PreSurveyPage/TimelineAccordian';
import { ANALYSIS_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh } from '../../../common/colors';

const AnalysisOverview = ({ overviewData }) => {
    return (
        <FlexColumnWithAlign gap={pxToVh(24)} className="full-width">
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={ANALYSIS_TEXT.OVERVIEW_SECT_HEADING}
                fontWeight={500}
            />
            <PhaseContentContainer>
                <TimelineAccordian data={overviewData} isEven={false} showPhaseDivider={false} />
            </PhaseContentContainer>
        </FlexColumnWithAlign>
    );
};

AnalysisOverview.defaultProps = {
    overviewData: {},
};

AnalysisOverview.propTypes = {
    overviewData: PropTypes.shape({
        note_text: PropTypes.string,
    }),
};

export default AnalysisOverview;
