import React, { useState } from 'react';
import { FlexBetween, FlexRow, FlexStartGap } from '../../../../common/styled/styled';
import { Box } from '@mui/material';
import FilterIcon from '../../../../assets/img/filter.svg';
import SearchBar from '../../../../common/FormInputs/SearchBar';
import UserthemeStyles from '../../themes';
import CustomDateRangePicker from '../../../../common/FormInputs/CustomDateRangePicker';
import FilterModal from '../../../../common/FormInputs/FilterModal';
import { LABELS } from '../../../../global-constants';
import PropTypes from 'prop-types';
import Outline24MoveLayerDown from '@mds/mds-icons/icons/svg/outline-24-move-layer-down.svg';
import Outline24TrashCan from '@mds/mds-icons/icons/svg/outline-24-trash-can.svg';
import { Icon } from '@mds/mds-reactjs-library';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CommonLabel, Img } from '../../../../common/FormInputs/Common';

export const ClientFilter = ({
    onSearchFn,
    onFilterFn,
    OnDateChangeFn,
    selectedIds,
    exportData,
    openFilterModal,
    filterModalFn = () => {},
    filterOpen = false,
    setOpen,
    setOpenConfirmModal = () => {},
    tab,
    filterParam,
    anchorEl,
    clientsData,
    usersData = [],
}) => {
    const styles = UserthemeStyles();
    const [filter, setFilter] = useState({ templateType: '', surveyCount: '' });

    const applyFilter = data => {
        onFilterFn(data);
        filterModalFn(false);
    };

    const handleBulkDelete = () => {
        if (tab === 1) {
            setOpen(true);
        } else {
            setOpen(true);
            setOpenConfirmModal({ btn: 'bulkDelete' });
        }
    };

    const enableBulk = selectedIds?.length > 0;
    const hasClientUserData = () => {
        if (tab == 1) {
            return usersData && usersData.length > 0;
        } else {
            return clientsData && clientsData.length > 0;
        }
    };

    return (
        <Box className={`${styles.filterParent}`}>
            <FlexBetween>
                <FlexStartGap gap={pxToVw(20)}>
                    <SearchBar
                        placeholder="Search"
                        name="clientname"
                        id="clientname"
                        onChangeFn={onSearchFn}
                        val={filterParam.search}
                        callApi={true}
                        tab={tab}
                    />
                    <CustomDateRangePicker OnDateChangeFn={OnDateChangeFn} />
                    <FlexRow onClick={openFilterModal} className={`${styles.filterTxt}`}>
                        <Img image={FilterIcon} h={pxToVh(16)} w={pxToVw(16)} alt="" />
                        <CommonLabel
                            textSize={14}
                            textColor={colorsCode.PrimaryDeepBlue}
                            textWeight={400}
                            lineHeight={20}
                            label={LABELS.FILTER}
                        />
                    </FlexRow>
                </FlexStartGap>
                <FlexStartGap gap={pxToVw(24)}>
                    <FlexStartGap
                        gap={pxToVw(8)}
                        onClick={enableBulk ? handleBulkDelete : undefined}
                        style={{ alignItems: 'center' }}
                    >
                        <Icon
                            src={Outline24TrashCan}
                            name="delete"
                            style={{
                                height: `${pxToVh(14)}vh`,
                                width: `${pxToVw(14)}vw`,
                                color: enableBulk ? colorsCode.SecondaryDeepBlue500 : colorsCode.Neutral30,
                            }}
                        />
                        <CommonLabel
                            textSize={13}
                            textWeight={400}
                            textColor={enableBulk ? colorsCode.SecondaryDeepBlue500 : colorsCode.Neutral30}
                            lineHeight={16}
                            ls={0.6}
                            label={LABELS.DELETE_CTA}
                            cursor={enableBulk ? 'pointer' : 'not-allowed'}
                            customStyles={{
                                textTransform: 'Uppercase',
                            }}
                        />
                    </FlexStartGap>
                    <FlexStartGap
                        gap={pxToVw(8)}
                        style={{
                            alignItems: 'center',
                            cursor: `${hasClientUserData() ? 'pointer' : 'not-allowed'}`,
                        }}
                        onClick={hasClientUserData() ? exportData : null}
                    >
                        <Icon
                            src={Outline24MoveLayerDown}
                            name="export"
                            style={{
                                height: `${pxToVh(14)}vh`,
                                width: `${pxToVw(14)}vw`,
                                color: hasClientUserData() ? colorsCode.SecondaryDeepBlue500 : colorsCode.Neutral30,
                            }}
                        />
                        <CommonLabel
                            textSize={13}
                            textWeight={400}
                            textColor={hasClientUserData() ? colorsCode.SecondaryDeepBlue500 : colorsCode.Neutral30}
                            lineHeight={16}
                            ls={0.6}
                            label={LABELS.EXPORT}
                            cursor="cursor"
                            customStyles={{
                                textTransform: 'Uppercase',
                            }}
                        />
                    </FlexStartGap>
                </FlexStartGap>
            </FlexBetween>
            <FilterModal
                cancelBtnName={LABELS.FILTER_MODAL.CANCEL_BTN}
                saveBtnName={LABELS.FILTER_MODAL.APPLY_BTN}
                mainTitle={LABELS.FILTER_MODAL.MAIN_TITLE}
                clearTitle={LABELS.FILTER_MODAL.CLEAR_TITLE}
                open={filterOpen}
                close={() => filterModalFn(null)}
                onApply={applyFilter}
                setFilter={setFilter}
                filter={filter}
                anchorEl={anchorEl}
                clientFilter={filterParam}
            />
        </Box>
    );
};

ClientFilter.propTypes = {
    onSearchFn: PropTypes.func.isRequired,
    OnDateChangeFn: PropTypes.func.isRequired,
    selectedIds: PropTypes.array.isRequired,
    exportData: PropTypes.func.isRequired,
    openFilterModal: PropTypes.func.isRequired,
    setOpen: PropTypes.func.isRequired,
    tab: PropTypes.number.isRequired,
    onFilterFn: PropTypes.func,
    filterModalFn: PropTypes.func,
    filterOpen: PropTypes.bool,
    setOpenConfirmModal: PropTypes.func,
    clientsData: PropTypes.array,
    usersData: PropTypes.array,
};

export default ClientFilter;
