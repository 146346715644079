import React, { memo, useEffect, useState } from 'react';
import { FlexColumn, FlexColumnGap, FlexStart, SectionDiv, Wrapper } from '../../../common/styled/styled';
import UserthemeStyles from '../../User/themes';
import BtnPlay from '../../../assets/img/Btn-play.svg';
import MonitorStyles from '../../Monitor/themes';
import { Typography } from '@mui/material';
import { CommonLabel } from '../../../common/FormInputs/Common';
import AuthStore from '../../../common/AuthStore';
import { colorsCode, pxToVh } from '../../../common/colors';
import ClosedSurveys from '../../Dashboard/partials/ClosedSurveys';
import { useDispatch, useSelector } from 'react-redux';
import themeStyles from '../../Dashboard/themes';
import { PATHS, SURVEY_STATUS, THROTTLE_TIME } from '../../../global-constants';
import { cloneDeep, debounce } from 'lodash';
import { GetAllSurveyListAction, GetAllSurveys, UserTemplates } from '../../../store/actions/Surveys/action';
import Loader from '../../Loader';
import { useNavigate } from 'react-router-dom';
import '../../Monitor/scss/index.scss';
import { setTemplate } from '../../../store/reducers/slice/CreateSurveySlice';

const Analytics = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const userStyles = UserthemeStyles();
    const styles = MonitorStyles();
    const theme = themeStyles();
    const [filters, setFilters] = useState({
        limit: 10,
        page: 1,
        q: '',
        startDate: '',
        endDate: '',
    });
    const {
        mySurveyList: { closed = [] },
        allSurveysList: { closed: allclosed = [] },
        loading: apiLoading = false,
    } = useSelector(state => state.surveyReducer);
    const { data = [], loading } = useSelector(state => state.userTemplates);
    const { COMPLETED } = SURVEY_STATUS;

    useEffect(() => {
        const apiCall = async () => {
            if (!data.length) {
                await dispatch(UserTemplates());
            }
            if (AuthStore.isAdminOrCoach) {
                await dispatch(GetAllSurveyListAction({ limit: 10, page: 1, status: COMPLETED }));
            } else {
                await dispatch(GetAllSurveys({ limit: 10, page: 1, status: COMPLETED }));
            }
        };
        apiCall();
    }, []);

    const OnDateChangeFn = (startDate, endDate) => {
        const copy = cloneDeep(filters);
        copy.startDate = startDate;
        copy.endDate = endDate;
        copy.page = 1;
        setFilters(copy);
        callApi(copy);
    };

    const onSearchFn = debounce(e => {
        const {
            target: { value },
        } = e;
        const copy = { ...filters, page: 1 };
        copy.q = value;
        setFilters(copy);
        callApi(copy);
    }, THROTTLE_TIME);

    const callApi = paramsObj => {
        const params = {
            limit: paramsObj.limit,
            page: paramsObj.page,
            q: paramsObj.q,
            dateRange: {
                startDate: paramsObj.startDate,
                endDate: paramsObj.endDate,
            },
            status: COMPLETED,
        };
        if (AuthStore.isAdminOrCoach) {
            dispatch(GetAllSurveyListAction(params));
        } else {
            dispatch(GetAllSurveys(params));
        }
    };

    const handleChangeFn = (e, value) => {
        const copy = { ...filters };
        copy.page = value;
        setFilters(copy);
        callApi(copy);
    };

    const handleItemPerPageFn = val => {
        const copy = { ...filters };
        copy.limit = val;
        setFilters(copy);
        callApi(copy);
    };

    const handleMonitorSurvey = uuid => {
        history(`${PATHS.MONITOR}/${PATHS.MONITOR_SURVEY}/${uuid}`);
    };

    const handleResume = (uuid, tid) => {
        dispatch(setTemplate(tid));
        history(`${PATHS.CREATE_SURVEY}/${tid}/${uuid}`);
    };

    return (
        <Wrapper data-testid="analytics">
            {(loading || apiLoading) && <Loader showBackground position="fixed" background="transparent" />}
            <SectionDiv className="monitor-section">
                <FlexColumn
                    className={`${styles.headerContainer} headerContainer`}
                    style={{ paddingBottom: '1.3vh', paddingTop: '4vh' }}
                >
                    <FlexStart className={`${userStyles.flexStartGap} titleContainer`}>
                        <Typography className={`${userStyles.clientText} titleClass`}>Analytics</Typography>
                        <img src={BtnPlay} className={`${userStyles.imgBtn}`} style={{ display: 'none' }} alt="" />
                    </FlexStart>
                </FlexColumn>
                <FlexColumnGap gap={pxToVh(24)} style={{ alignItems: 'inherit', marginTop: '4vh' }}>
                    <CommonLabel
                        textSize={24}
                        textWeight={500}
                        lineHeight={28}
                        textColor={colorsCode.TextHeading}
                        label="Closed surveys"
                    />
                    <ClosedSurveys
                        styles={theme}
                        listing={
                            AuthStore.isAdminOrCoach ? allclosed?.data?.records || [] : closed?.data?.records || []
                        }
                        data={data}
                        search={filters.q}
                        onSearchFn={onSearchFn}
                        OnDateChangeFn={OnDateChangeFn}
                        pagination={true}
                        total={AuthStore.isAdminOrCoach ? allclosed?.data?.total || 0 : closed?.data?.total || 0}
                        handleChangeFn={handleChangeFn}
                        page={filters.page}
                        handleItemPerPageFn={handleItemPerPageFn}
                        noDataMessage="No closed survey found"
                        handleMonitorSurvey={handleMonitorSurvey}
                        limit={filters.limit}
                        handleResume={handleResume}
                    />
                </FlexColumnGap>
            </SectionDiv>
        </Wrapper>
    );
};

export default memo(Analytics);
