import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { FlexColumnWithAlign } from '../../../common/styled/styled';
import { Container, Icon } from './styles';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const ScoreCard = ({ heading, subheading, icon, padding, backgroundColor, width, height }) => {
    return (
        <Container gap={pxToVw(25)} padding={padding} backgroundColor={backgroundColor} width={width} height={height}>
            <Icon src={icon} alt="card-icon" />
            <FlexColumnWithAlign gap={pxToVh(1)}>
                <Text
                    fontColor={colorsCode.PrimaryDeepBlue}
                    fontSize={24}
                    lineHeight={28}
                    label={heading}
                    fontWeight={500}
                />
                <Text fontColor={colorsCode.Neutral80} fontSize={16} lineHeight={24} label={subheading} />
            </FlexColumnWithAlign>
        </Container>
    );
};

ScoreCard.defaultProps = {
    subheading: null,
    icon: null,
    padding: null,
    height: null,
    width: null,
    backgroundColor: null,
};

ScoreCard.propTypes = {
    heading: PropTypes.string.isRequired,
    subheading: PropTypes.string,
    icon: PropTypes.string,
    padding: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    backgroundColor: PropTypes.string,
};

export default ScoreCard;
