import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import {
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
    FlexBetween,
    FlexColumnWithAlign,
    FlexColumn,
} from '../../../common/styled/styled';
import { colorsCode, pxToVh } from '../../../common/colors';
import { ExpandMore } from '@mui/icons-material';
import indivisual from '../../../assets/img/indivisual.svg';
import optional_option from '../../../assets/img/optional_option.svg';
import talent from '../../../assets/img/talent.svg';
import wellBeing from '../../../assets/img/well_being.svg';
import career from '../../../assets/img/career.svg';
import { CircleImage, Image } from './styles';

const ExExplanation = ({ data }) => {
    const section_images = [career, talent, wellBeing, indivisual, optional_option];

    return (
        <FlexBetween className="full-width" margin={`0 0 ${pxToVh(74)}vh ${pxToVh(0)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(5)}>
                <FlexColumnWithAlign gap={pxToVh(15)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={24}
                        lineHeight={28}
                        fontWeight={500}
                        label={data.title}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        fontWeight={400}
                        label={data.sub_title}
                    />
                </FlexColumnWithAlign>
                <FlexColumnWithAlign className="full-width" style={{ marginTop: '20px' }}>
                    {data.options.map((option, index) => {
                        return (
                            <FlexColumnWithAlign key={option.key} className="full-width">
                                <CircleImage>
                                    <Image src={section_images[index]} alt={`key-activity-${index}`} />
                                </CircleImage>
                                <Text
                                    fontColor={colorsCode.title}
                                    fontSize={20}
                                    lineHeight={32}
                                    fontWeight={500}
                                    label={option.title}
                                />
                                <FlexColumn className="full-width" style={{ marginBottom: `${pxToVh(30)}vh` }}>
                                    {option.steps.map(step => {
                                        return (
                                            <AccordionStyled
                                                key={step.key}
                                                defaultExpanded={step.isOpen}
                                                paddingBottom={8}
                                                noDoubleBorder
                                            >
                                                <AccordionSummaryStyled
                                                    style={{ paddingLeft: '0px' }}
                                                    expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                                >
                                                    <Text
                                                        fontColor={colorsCode.title}
                                                        fontSize={16}
                                                        lineHeight={24}
                                                        fontWeight={500}
                                                        label={step.title}
                                                    />
                                                </AccordionSummaryStyled>
                                                <AccordionDetailsStyled style={{ paddingTop: '0px' }}>
                                                    <Text
                                                        fontColor={colorsCode.TextSubtle}
                                                        fontSize={16}
                                                        lineHeight={24}
                                                        fontWeight={400}
                                                        label={step.body}
                                                    />
                                                </AccordionDetailsStyled>
                                            </AccordionStyled>
                                        );
                                    })}
                                </FlexColumn>
                            </FlexColumnWithAlign>
                        );
                    })}
                </FlexColumnWithAlign>
            </FlexColumnWithAlign>
        </FlexBetween>
    );
};

ExExplanation.defaultProps = {
    data: {},
};

ExExplanation.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({})),
};

export default ExExplanation;
