import React from 'react';
import PropTypes from 'prop-types';
import Text from '../Text';
import { FlexColumnWithAlign, FlexRow } from '../../../common/styled/styled';
import { Container, ListContainer } from './styles';
import objectiveIcon from '../../../assets/img/objective.svg';
import personEdit from '../../../assets/img/a-edit.svg';
import { LABELS } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const ObjectiveComponent = ({ data, padding, backgroundColor }) => {
    return (
        <Container gap={pxToVh(16)} padding={padding} backgroundColor={backgroundColor}>
            <FlexRow gap={pxToVw(22)} alignItems="flex-start">
                <img src={objectiveIcon} alt="objective" style={{ marginRight: '-6px' }} />
                <FlexColumnWithAlign gap={pxToVh(4)}>
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        label={LABELS.OBJECTIVE}
                        fontWeight={500}
                    />
                    <ListContainer>
                        {data?.objective_list?.map(objective => {
                            return (
                                <Text
                                    key={objective?.key}
                                    fontColor={colorsCode.TextSubtle}
                                    fontSize={16}
                                    lineHeight={24}
                                    label={objective?.value}
                                    fontWeight={400}
                                    component="li"
                                />
                            );
                        })}
                    </ListContainer>
                </FlexColumnWithAlign>
            </FlexRow>
            {data?.person_responsible_text ? (
                <FlexRow gap={pxToVw(22)}>
                    <img src={personEdit} alt="person-edit" />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        label={data?.person_responsible_text}
                        fontWeight={500}
                    />
                </FlexRow>
            ) : null}
        </Container>
    );
};

ObjectiveComponent.defaultProps = {
    padding: null,
    backgroundColor: null,
    data: {},
};

ObjectiveComponent.propTypes = {
    padding: PropTypes.string,
    backgroundColor: PropTypes.string,
    data: PropTypes.shape({
        objective_list: PropTypes.arrayOf(PropTypes.shape({})),
        person_responsible_text: PropTypes.shape({}),
    }),
};

export default ObjectiveComponent;
