import React from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import Button from '../../../components/KnowledgeCenter/Button';
import { FlexBetween, FlexColumnWithAlign } from '../../../common/styled/styled';
import { VideoContainer, TourTextContainer, ChipStyled } from './styles';
import { PlayIcon, VideoThumbnail } from '../../../components/KnowledgeCenter/ContentCard/styles';
import videoIcon from '../../../assets/img/video-play.svg';
import { LANDING_PAGE_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh } from '../../../common/colors';

const IntroComponent = ({ onUpdateTourStatus, onClickVideo, data }) => {
    const hideTourCTA = true;

    return (
        <FlexBetween className="full-width" margin={`0 0 ${pxToVh(74)}vh`}>
            <TourTextContainer gap={pxToVh(51)}>
                <FlexColumnWithAlign gap={pxToVh(8)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={40}
                        lineHeight={50}
                        fontWeight={300}
                        label={LANDING_PAGE_TEXT.TOUR_HEADING}
                    />
                    <Text
                        fontColor={colorsCode.TextSubtle}
                        fontSize={20}
                        lineHeight={32}
                        label={LANDING_PAGE_TEXT.TAKE_TOUR_CTA}
                    />
                </FlexColumnWithAlign>
                {!hideTourCTA ? (
                    <Button
                        fontColor={colorsCode.PrimaryDeepBlue}
                        fontSize={20}
                        lineHeight={32}
                        label={LANDING_PAGE_TEXT.TOUR_TAKEN_CTA}
                        variant="text"
                        isUppercase={false}
                        isUnderlined
                        padding="0"
                        onClick={() => onUpdateTourStatus()}
                    />
                ) : null}
            </TourTextContainer>
            <VideoContainer>
                <VideoThumbnail src={data.url} />
                <PlayIcon src={videoIcon} alt="video-icon" onClick={() => onClickVideo(data.url)} />
                <ChipStyled label={LANDING_PAGE_TEXT.INTRO_CHIP} />
            </VideoContainer>
        </FlexBetween>
    );
};

IntroComponent.defaultProps = {
    data: {},
};

IntroComponent.propTypes = {
    onUpdateTourStatus: PropTypes.func.isRequired,
    onClickVideo: PropTypes.func.isRequired,
    data: PropTypes.shape({
        thumbnail: PropTypes.string,
        url: PropTypes.string,
    }),
};

export default IntroComponent;
