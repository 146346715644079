import React, { useRef, useContext, useEffect, useState } from 'react';
import Text from '../../../components/KnowledgeCenter/Text';
import { Section, FlexColumnWithAlign } from '../../../common/styled/styled';
import OutcomeAndPracticesSection from './OutcomeAndPracticesSection';
import { GLOSSARY_TEXT, KC_VERSION_KEYS, SECTION_KEYS } from '../../../utils/kc-constants';
import {
    GLOSSARY_OUTCOMES_PRACTICE_DATA,
    GLOSSARY_OUTCOMES_PRACTICE_DATA_4,
    OTHER_SECTION_DATA,
    WORK_FLEXIBLE_DATA,
} from '../../../utils/kc-data';
import { AppContext } from '../Home';
import { useIntersection } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { useLocation } from 'react-router';
import OtherSection from './OtherSection';
import WorkPlace from './WorkPlace';

const GlossaryPage = () => {
    const refs = useRef([]);
    const { setActiveSection } = useContext(AppContext);
    const isVisible = useIntersection(refs);

    const [isVersionFour, setVersion] = useState(true);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, [isVersionFour, setVersion]);

    useEffect(() => {
        if (isVisible) {
            setActiveSection(isVisible);
        }
    }, [isVisible, setActiveSection]);

    return (
        <Section gap={pxToVh(60)} padding={`${pxToVh(70)}vh ${pxToVw(20)}vw ${pxToVh(48)}vh ${pxToVw(59)}vw`}>
            <FlexColumnWithAlign gap={pxToVh(16)}>
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={44}
                    lineHeight={52}
                    label={GLOSSARY_TEXT.GLOSSARY_HEADING}
                    fontWeight={500}
                />
                <Text
                    fontColor={colorsCode.Neutral80}
                    fontSize={24}
                    lineHeight={36}
                    label={GLOSSARY_TEXT.GLOSSARY_DESCRIPTION}
                />
            </FlexColumnWithAlign>
            <FlexColumnWithAlign ref={element => (refs.current[0] = element)} id={SECTION_KEYS.GLOSSARY_OUTCOMES}>
                <OutcomeAndPracticesSection
                    outcomesData={isVersionFour ? GLOSSARY_OUTCOMES_PRACTICE_DATA_4 : GLOSSARY_OUTCOMES_PRACTICE_DATA}
                />
            </FlexColumnWithAlign>
            {isVersionFour && (
                <FlexColumnWithAlign ref={element => (refs.current[1] = element)} id={SECTION_KEYS.ADDITIONAL_SECTIONS}>
                    <OtherSection data={OTHER_SECTION_DATA} />
                    <WorkPlace data={WORK_FLEXIBLE_DATA} />
                </FlexColumnWithAlign>
            )}
        </Section>
    );
};

GlossaryPage.defaultProps = {};

GlossaryPage.propTypes = {};

export default GlossaryPage;
