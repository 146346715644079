import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Text from '../../../components/KnowledgeCenter/Text';
import Tooltip from '../../../components/KnowledgeCenter/Tooltip';
import Chip from '../../../components/KnowledgeCenter/Chip';
import { FlexBetween, FlexColumnWithAlign, FlexRow, FootNote } from '../../../common/styled/styled';
import { ImageContainer, Image } from '../OverviewPage/styles';
import { PhaseContentContainer, ComponentsDesignParentDiv, ParentContentDiv, WarningInfoContainer } from './styles';
import warningIcon from '../../../assets/img/warning.svg';
import { KC_VERSION_KEYS, SETUP_SURVEY_TEXT } from '../../../utils/kc-constants';
import DemographicsTable from './DemographicsTable';
import { ListContainer, OrderedListContainer } from '../../../components/KnowledgeCenter/ObjectiveComponent/styles';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { useLocation } from 'react-router';

const SurveyComponentsDesign = ({ componentsDesignData }) => {
    const [isVersionFour, setVersion] = useState(false);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, [isVersionFour, setVersion]);

    return (
        <FlexColumnWithAlign gap={pxToVh(29)} className="full-width">
            <Text
                fontColor={colorsCode.PrimaryDeepBlue}
                fontSize={36}
                lineHeight={44}
                label={SETUP_SURVEY_TEXT.COMPONENTS_SECTION_HEADING}
                fontWeight={500}
            />
            <PhaseContentContainer gap={pxToVh(40)}>
                {componentsDesignData.map((data, index) => {
                    return (
                        <ComponentsDesignParentDiv key={data.key} gap="0">
                            <ParentContentDiv gap={pxToVw(43)} className="content-div" tableData={!!data?.tableData}>
                                <FlexColumnWithAlign>
                                    <FlexColumnWithAlign gap={pxToVh(15)}>
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={24}
                                            lineHeight={28}
                                            label={data.heading}
                                            fontWeight={500}
                                        />
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={16}
                                            lineHeight={24}
                                            label={data.body}
                                        />
                                        {data?.tableData ? <DemographicsTable data={data.tableData} /> : null}
                                        {data?.listing_data ? (
                                            <OrderedListContainer margin={`-${pxToVh(11)}vh 0 0 0`}>
                                                {data?.listing_data?.map((list, liIndex) => {
                                                    return (
                                                        <li key={`listing-data-${data.heading}-${liIndex}`}>
                                                            <Text
                                                                fontColor={colorsCode.Neutral80}
                                                                fontSize={16}
                                                                lineHeight={24}
                                                                fontWeight={500}
                                                                component="p"
                                                                label={list.heading}
                                                            />
                                                            <Text
                                                                fontColor={colorsCode.Neutral80}
                                                                fontSize={16}
                                                                lineHeight={24}
                                                            >
                                                                {list.body}
                                                                {list.foot_note && (
                                                                    <Tooltip text={list.foot_note.tooltip_text}>
                                                                        <FootNote>
                                                                            <Text
                                                                                fontColor={
                                                                                    colorsCode.SecondaryDeepBlue500
                                                                                }
                                                                                fontSize={12}
                                                                                lineHeight={14}
                                                                                fontWeight={300}
                                                                                label={list.foot_note.label}
                                                                                cursor="inherit"
                                                                            />
                                                                        </FootNote>
                                                                    </Tooltip>
                                                                )}
                                                            </Text>
                                                        </li>
                                                    );
                                                })}
                                            </OrderedListContainer>
                                        ) : null}
                                    </FlexColumnWithAlign>
                                </FlexColumnWithAlign>
                                <ImageContainer className={data.image_placement}>
                                    <Image src={data.image_url} alt={`components-design-${index}`} className="img" />
                                </ImageContainer>
                            </ParentContentDiv>
                            {data.legal_consent ? (
                                <FlexColumnWithAlign gap={pxToVh(24)} margin={`${pxToVh(43)}vh 0 0`}>
                                    {isVersionFour && (
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={16}
                                            lineHeight={28}
                                            label={SETUP_SURVEY_TEXT.LEGAL_CONSENT_SUB_HEADING}
                                            fontWeight={400}
                                        />
                                    )}
                                    <FlexBetween className="full-width" alignItems="flex-start">
                                        <Text
                                            fontColor={colorsCode.Neutral80}
                                            fontSize={24}
                                            lineHeight={28}
                                            label={SETUP_SURVEY_TEXT.LEGAL_CONSENT_HEADING}
                                            fontWeight={500}
                                        />
                                    </FlexBetween>
                                    <Chip
                                        icon={<img src={warningIcon} alt="warning" style={{ marginTop: '0.8vh' }} />}
                                        fontSize={20}
                                        lineHeight={26}
                                        fontColor={colorsCode.Neutral80}
                                        padding={`${pxToVh(14)}vh ${pxToVw(32)}vw`}
                                        borderRadius="12px"
                                        label={data?.legal_consent?.info_text}
                                        multiline
                                        alignItems="flex-start"
                                    />
                                    <Text
                                        fontColor={colorsCode.Neutral30}
                                        fontSize={16}
                                        lineHeight={24}
                                        label={data?.legal_consent?.description}
                                        fontWeight={400}
                                    />
                                </FlexColumnWithAlign>
                            ) : null}
                            {data.note ? (
                                <WarningInfoContainer gap={pxToVh(12)} hasMargin>
                                    {!data?.note?.hide_icon ? <img src={warningIcon} alt="warning" /> : null}
                                    <FlexColumnWithAlign>
                                        {data.note.text && (
                                            <Text
                                                fontColor={colorsCode.Neutral80}
                                                fontSize={14}
                                                lineHeight={20}
                                                label={data.note?.text}
                                            />
                                        )}
                                        {Array.isArray(data.note) && (
                                            <ListContainer>
                                                {data.note.map((option, index) => {
                                                    return (
                                                        <FlexRow key={`note-${index}`}>
                                                            <Text
                                                                fontColor={colorsCode.Neutral80}
                                                                fontSize={14}
                                                                lineHeight={20}
                                                                component="li"
                                                            >
                                                                {option.text}
                                                                {option.foot_note && (
                                                                    <Tooltip text={option.foot_note.tooltip_text}>
                                                                        <FootNote>
                                                                            <Text
                                                                                fontColor={
                                                                                    colorsCode.SecondaryDeepBlue500
                                                                                }
                                                                                fontSize={12}
                                                                                lineHeight={14}
                                                                                fontWeight={300}
                                                                                label={option.foot_note.label}
                                                                                cursor="inherit"
                                                                            />
                                                                        </FootNote>
                                                                    </Tooltip>
                                                                )}
                                                            </Text>
                                                        </FlexRow>
                                                    );
                                                })}
                                            </ListContainer>
                                        )}
                                    </FlexColumnWithAlign>
                                </WarningInfoContainer>
                            ) : null}
                        </ComponentsDesignParentDiv>
                    );
                })}
            </PhaseContentContainer>
        </FlexColumnWithAlign>
    );
};

SurveyComponentsDesign.defaultProps = {
    componentsDesignData: [],
};

SurveyComponentsDesign.propTypes = {
    componentsDesignData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default SurveyComponentsDesign;
