import { makeStyles } from '@mui/styles';

const TableThemeStyles = makeStyles(() => {
    return {
        clientName: {
            width: '19vw',
        },
        linkType: {
            width: '15vw',
        },
        surveyType: {},
        user: {
            width: '11vw',
        },
        surveyCreated: {
            width: '12vw',
        },
    };
});

export default TableThemeStyles;
