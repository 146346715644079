import { createSlice, current } from '@reduxjs/toolkit';
import {
    GetDemographicQues,
    GetStandardDemoQues,
    SaveStandardDemo,
    updateStandardCustomDemo,
    updateInstructionDemo,
} from '../../actions/Demographics/actions';
import { cloneDeep } from 'lodash';

const INITIAL_STATE = {
    loading: false,
    error: false,
    errorMessage: '',
    standard: [],
    demo: [],
    isSaved: false,
    isStandardSaved: false,
    isCustom: false,
    isUpdated: false,
    subGroup: {},
};

const Demographics = createSlice({
    name: 'DemographicsReducer',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(GetStandardDemoQues.pending, state => {
                state.loading = true;
            })
            .addCase(GetStandardDemoQues.fulfilled, (state, { payload }) => {
                const arrayResponse =
                    payload?.data?.records && Object.keys(payload?.data?.records).length
                        ? Object.values(payload.data.records)
                        : [];
                const data = arrayResponse.length
                    ? arrayResponse.map(item => {
                          return { ...item, is_focus: false, is_edited: false };
                      })
                    : [];
                state.loading = false;
                state.standard = {
                    records: data,
                    scale: payload.data.scale,
                };
                state.subGroup = payload.data.sub_group;
            })
            .addCase(GetStandardDemoQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = false;
                state.errorMessage = payload;
            })
            .addCase(SaveStandardDemo.pending, state => {
                state.loading = true;
            })
            .addCase(SaveStandardDemo.fulfilled, state => {
                state.loading = false;
                state.isStandardSaved = true;
            })
            .addCase(SaveStandardDemo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(GetDemographicQues.pending, state => {
                state.loading = true;
            })
            .addCase(GetDemographicQues.fulfilled, (state, { payload }) => {
                const arrayResponse =
                    payload?.data?.records && Object.keys(payload?.data?.records).length
                        ? Object.values(payload.data.records).map(item => {
                              return { ...item, is_focus: false, is_edited: false, showTranslation: false };
                          })
                        : [];
                state.loading = false;
                state.demo = {
                    records: arrayResponse,
                    scale: payload.data.scale,
                };
                state.subGroup = payload.data.sub_group;
            })
            .addCase(GetDemographicQues.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = false;
                state.errorMessage = payload;
            })
            .addCase(updateStandardCustomDemo.pending, state => {
                state.loading = true;
            })
            .addCase(updateStandardCustomDemo.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateStandardCustomDemo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(updateInstructionDemo.pending, state => {
                state.loading = true;
            })
            .addCase(updateInstructionDemo.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateInstructionDemo.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetDemo: state => {
            state.isSaved = false;
            state.error = false;
            state.isStandardSaved = false;
            state.errorMessage = '';
            state.isUpdated = false;
        },
        resetData: state => {
            state.standard = [];
            state.errorMessage = '';
            state.isUpdated = false;
            state.error = false;
        },
        setCustomTab: (state, { payload }) => {
            state.isCustom = payload;
        },
        updateStandard: (state, { payload }) => {
            const { questionId, use_in_survey, demo_visible } = payload;
            const copy = cloneDeep(current(state).standard?.records);
            const findIndex = copy.findIndex(({ uuid }) => questionId === uuid);
            if (typeof use_in_survey == 'boolean') {
                copy[findIndex].use_in_survey = use_in_survey;
            }
            if (demo_visible) {
                copy[findIndex].indi_gen_question = demo_visible?.question_link_type;
                copy[findIndex].use_in_survey = demo_visible?.use_in_survey;
            }
            state.standard = {
                records: copy,
                scale: current(state).standard?.scale,
            };
        },
    },
});

export const { resetDemo, resetData, setCustomTab, updateStandard, updateStandardState } = Demographics.actions;
export default Demographics.reducer;
