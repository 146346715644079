/* istanbul ignore file */
export const Endpoints = {
    loginUrl: '/api/authentication/access-token',
    surveyTemplateListingUrl: '/api/survey-templates?limit=__limit__&page=__page__',
    clientListingUrl: '/api/clients',
    allClientsListUrl: '/api/clients/all-clients',
    permissionUrl: '/api/users/user-permissions',
    industryUrl: '/api/industries?limit=__limit__&page=__page__',
    extraAttributesUrl: '/api/survey-extra-attributes',
    addClient: '/api/clients',
    userListingUrl: '/api/users',
    bulkDeleteClientUrl: '/api/clients/bulk-delete',
    roles: '/api/roles?limit=__limit__&page=__page__',
    usersAll: '/api/users/all-users?limit=__limit__&page=__page__',
    userExists: '/api/users/exists',
    clientDetails: '/api/clients/:client_id',
    usersDetails: '/api/users/:user_id',
    addUser: '/api/users',
    bulkDeleteUser: '/api/users/bulk-delete',
    surveyList: 'api/survey/my-survey',
    userTemplates: '/api/users/user-survey-template',
    surveyTemplates: '/api/survey-templates/:tid/languages',
    createSurvey: '/api/survey',
    getWording: '/api/survey/:survey_id/wordings',
    getAdditionalModules: 'api/survey/:survey_id/question-modules',
    viewAllQUES: 'api/survey/:survey_id/question-group/:question_sub_group_id/questions?is_rejected=false',
    updateAdditionalModules: 'api/survey/:survey_id/questions-group/:question_sub_group_id/add-to-survey',
    getSurveyOverview: '/api/survey/:survey_id/overview-static-words',
    getQuestions: '/api/survey/:survey_id/group/questions',
    getQtnScale: '/api/survey/:survey_id/question/scale',
    postQuestions: '/api/survey/:survey_id/questions',
    reorderQuestions: '/api/survey/:survey_id/reorder-questions',
    searchOpportunity: '/api/salesforce-info',
    getOpportunityLogs: '/api/salesforce-info',
    saveSalesforceInfo: '/api/salesforce-info',
    deleteOpportunity: '/api/salesforce-info',
    getStandardDemo: '/api/survey/:survey_id/group/questions',
    saveStandardDemo: '/api/survey/:survey_id/questions/:question_id',
    updateMultipleQuestions: '/api/survey/:survey_id/questions/update-use-in-flag',
    saveCustomDemo: '/api/survey/:survey_id/demographic-open-custom-questions',
    updateCustomDemo: '/api/survey/:survey_id/demographic-open-custom-questions/:question_id',
    surveyConfig: '/api/survey/:survey_id/config',
    bulkApproveReject: '/api/survey/:survey_id/bulk-approve-reject-questions',
    surveyUploadLogo: '/api/survey/:survey_id/upload-logo',
    surveyStatusUrl: '/api/survey/:survey_id/update-status',
    surveyRemoveLogo: '/api/survey/:survey_id/delete-logo',
    surveyPreviewLink: 'api/survey/:survey_id/survey_preview_url',
    refreshToken: '/api/authentication/refresh-token',
    getAllSurveys: '/api/survey/all-surveys',
    updateAdditionalQues: '/api/survey/:survey_id/questions/:question_id',
    getLanguages: '/api/languages',
    updateBulkSurvey: '/api/survey/bulk-survey-update-status',
    monitorFilterOptions: 'api/respondents/:survey_id/filters',
    monitorDemoResponses: 'api/survey/:survey_id/demographic/questions/responses',
    monitorResponseRate: 'api/survey/:survey_id/response/rate',
    monitorUpdateRespondents: 'api/survey/:survey_id/update-respondents',
    monitorResponseThreshold: 'api/survey/:survey_id/threshold',
    exportFile: '/api/survey/:survey_id/export?language_id=:langid',
    exportAsnwerQuestions: '/api/survey/:survey_id/data-file-export-v2',
    ohiTemplateQuestionGet: '/api/survey/:survey_id/standard/questions',
    ohiTemplateQuestionPut: '/api/survey/:survey_id/question/:question_id',
    ohiTemplateSubGroupQuestionPut: '/api/survey/:survey_id/sub-group-description/:question_id',
    additionalModule: '/api/survey/:survey_id/modules/questions-with-instructions',
    updateInstructions: '/api/survey/:survey_id/module/:ques_sub_group_id/instruction',
    getClosingProtocol: '/api/closing-protocol/raw-data/:survey_id',
    closingProtocolEndpoint: '/api/closing-protocol/:survey_id',
    salesforceEndpoint: '/api/salesforce-info/:opportunity_name',
    getTextEditTabs: '/api/survey/:survey_id/text-edit/:group_type',
    getTextEditTabsData: '/api/survey/:survey_id/text-edit/:sub_group/:group_type/data',
    feedbackSubject: 'api/feedback/feedback-subject',
    feedbackSubmit: 'api/feedback/save-feedback',
    getGlossary: 'api/survey/:survey_id/glossary',
    updateGlossary: 'api/survey/:survey_id/glossary/:uuid',
    maintenanceMode: '/api/survey/:survey_id/update-maintenance',
    openTextAdditionalQuest: '/api/survey/:survey_id/questions/set_ma_opentext/:question_id',
    exportAllSurveys: 'api/survey/export-surveys',
    dataProcessSync: '/api/survey/process-slinks',
};
