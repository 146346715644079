/* istanbul ignore file */
import { makeStyles } from '@mui/styles';
import { colorsCode, pxToVh, pxToVw } from '../common/colors';

const themeStyles = makeStyles(() => {
    return {
        login: {
            left: '0px',
            top: '0px',
            width: '100%',
            height: '100%',
        },
        oktaClass: {
            textDecoration: 'underline',
            color: '#FFF9EF',
            cursor: 'pointer',
            paddingRight: '5px',
        },
        inactiveClass: {
            display: 'inline-block',
            maxWidth: '100.6%',
            overflow: 'hidden',
            width: '100.6%',
            height: '6.4vh',
        },
        activeClass: {
            background: 'white',
            border: '1px solid white',
            borderRadius: '8px',
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px',
        },
        logoDiv: {
            height: '7vh',
            margin: '5vh 3vh',
        },
        logoImg: {
            width: '9vw',
            cursor: 'pointer',
        },
        MenuClass: {
            height: '9vh',
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '2vw',
            alignItems: 'center',
        },
        MenuTypoClass: {
            color: 'white !important',
            fontSize: `${pxToVh(16)}vh !important`,
            padding: '0 1vw',
            cursor: 'pointer',
            fontWeight: '300 !important',
            lineHeight: '2.7vh !important',
        },
        profileParent: {
            bottom: '0',
            position: 'absolute',
            marginBottom: '2vh',
            padding: '1vw',
            width: '100%',
        },
        logoutParent: {
            padding: `${pxToVh(8)}vh 0`,
            borderRadius: '2px',
            boxShadow: '0 2px 4px #000',
            paddingLeft: '1vw',
        },
        profileClass: {
            background: 'white',
            height: '6.8vh',
            width: '6.8vh',
            marginLeft: '3.5vw',
            marginTop: '1vh',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        imgClass: {
            width: `${pxToVw(24)}vw`,
            height: `${pxToVh(24)}vh`,
            marginLeft: '1vw',
            cursor: 'pointer',
        },
        imgIndex: {
            zIndex: '2',
        },
        flexRowClass: {
            gap: '4vh !important',
            alignItems: 'flex-start !important',
            paddingLeft: '2vw',
        },
        textClassActive: {
            color: '#051C2C !important',
            fontWeight: '400 !important',
            fontSize: `${pxToVh(16)}vh !important`,
        },
        logout: {
            fontSize: '1.98vh !important',
            lineHeight: '2.83vh !imporant',
            cursor: 'pointer',
            color: `${colorsCode.white}`,
        },
        logoutImg: {
            height: '1.7vh',
            width: '0.83vw',
            cursor: 'pointer',
        },
        initials: {
            color: 'black',
        },
    };
});

export default themeStyles;
