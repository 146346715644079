import React, { Fragment, useEffect, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import Text from '../../../components/KnowledgeCenter/Text';
import PropTypes from 'prop-types';
import { FlexBetween, FlexColumn, FlexColumnGap, FlexRow } from '../../../common/styled/styled';
import Button from '../../../components/KnowledgeCenter/Button';
import { CardContainer, Underline, TitleDiv, CurvedDiv, OHIInsightContainer, OHIStepImageContainer } from './styles';
import { LABELS } from '../../../global-constants';
import { CONFIG, KC_VERSION_KEYS } from '../../../utils/kc-constants';
import cloudDownload from '../../../assets/img/cloud-download.svg';
import { downloadURL } from '../../../utils/functions';
import ohi_index_step1 from '../../../assets/img/ohi_index_step1.svg';
import ohi_index_step2 from '../../../assets/img/ohi_index_step2.svg';
import { OHIInsightText } from '../../../utils/kc-data';
import { useLocation } from 'react-router';

const OHIInsight = ({ data, footerData }) => {
    const [isVersionFour, setVersion] = useState(false);
    const location = useLocation();
    const { search } = location;
    const params = new URLSearchParams(search);
    const images = [ohi_index_step1, ohi_index_step2, ohi_index_step2];

    useEffect(() => {
        let version = params.get('version');
        setVersion(version === KC_VERSION_KEYS.FOUR);
    }, []);

    return (
        <Fragment>
            <FlexBetween alignItems="flex-start" className="full-width">
                <Text
                    fontColor={colorsCode.$textColor}
                    fontSize={24}
                    lineHeight={28}
                    label={OHIInsightText.ohiHealthIndex}
                    fontWeight={500}
                />
                <div style={{ marginRight: '8px' }}>
                    <Button
                        fontSize={16}
                        lineHeight={24}
                        label={LABELS.DOWNLOAD}
                        startIcon={<img src={cloudDownload} alt="cloud-download" />}
                        padding={`${pxToVh(8)} ${pxToVw(16)}`}
                        isUppercase={false}
                        onClick={() =>
                            downloadURL(
                                isVersionFour
                                    ? `${CONFIG.BASE_IMG_URL}/example_output_OHI_workshop_1_4.pptx`
                                    : `${CONFIG.BASE_IMG_URL}/example_output_OHI_workshop_1.pptx`
                            )
                        }
                    />
                </div>
            </FlexBetween>
            <OHIInsightContainer style={{ backgroundColor: 'white', marginTop: `${pxToVh(8)}vh` }}>
                <FlexRow style={{ justifyContent: 'space-around' }}>
                    <div style={{ width: '20%', height: '0px' }}></div>
                    <FlexRow style={{ justifyContent: 'space-around', width: '100%', alignItems: 'center' }}>
                        <CurvedDiv>{OHIInsightText.driven_by}</CurvedDiv>
                        <CurvedDiv>{OHIInsightText.example}</CurvedDiv>
                    </FlexRow>
                </FlexRow>
                <OHIInsightContainer>
                    <FlexColumn>
                        {data.map((obj, index) => (
                            <FlexRow key={obj.key} gap={pxToVw(8)} style={{ width: '100%', alignItems: 'stretch' }}>
                                <OHIStepImageContainer
                                    style={{ backgroundImage: `url(${images[index]})` }}
                                    key={obj.key}
                                >
                                    <div
                                        style={{
                                            paddingTop: index == 0 ? '40px' : '80px',
                                            paddingLeft: '40px',
                                            paddingRight: '20px',
                                        }}
                                    >
                                        <Text
                                            fontColor={colorsCode.$textColor}
                                            fontSize={24}
                                            lineHeight={28}
                                            label={obj.step.body}
                                            fontWeight={500}
                                        />
                                    </div>
                                </OHIStepImageContainer>
                                {index == 0 ? (
                                    <FlexColumnGap gap={pxToVw(5)} style={{ width: '100%', alignItems: 'stretch' }}>
                                        <CardContainer width={'100%'} height={'45%'}>
                                            <FlexColumn>
                                                <TitleDiv>{obj.drive_by.title}</TitleDiv>
                                                <Underline />
                                                <Text
                                                    fontColor={colorsCode.$textColor}
                                                    fontSize={24}
                                                    lineHeight={28}
                                                    label={obj.drive_by.body}
                                                    fontWeight={500}
                                                />
                                            </FlexColumn>
                                        </CardContainer>
                                        <CardContainer width={'100%'} height={'45%'} key={obj.key}>
                                            <FlexColumn>
                                                <TitleDiv>{obj.drive_by.child_title}</TitleDiv>
                                                <Underline />
                                                <Text
                                                    fontColor={colorsCode.$textColor}
                                                    fontSize={24}
                                                    lineHeight={28}
                                                    label={obj.drive_by.child_body}
                                                    fontWeight={500}
                                                />
                                            </FlexColumn>
                                        </CardContainer>
                                    </FlexColumnGap>
                                ) : (
                                    <FlexColumn style={{ width: '100%' }}>
                                        <CardContainer width={'100%'} height={'90%'} key={obj.key}>
                                            <TitleDiv>{obj.drive_by.title}</TitleDiv>
                                            <Underline />
                                            <Text
                                                fontColor={colorsCode.$textColor}
                                                fontSize={24}
                                                lineHeight={28}
                                                label={obj.drive_by.body}
                                                fontWeight={500}
                                            />
                                        </CardContainer>
                                    </FlexColumn>
                                )}
                                <FlexColumn style={{ width: '100%' }}>
                                    <CardContainer width={'100%'} height={'90%'} key={obj.key}>
                                        <FlexColumn>
                                            <TitleDiv>{obj.example.title}</TitleDiv>
                                            <Underline />
                                            <Text
                                                fontColor={colorsCode.$textColor}
                                                fontSize={24}
                                                lineHeight={28}
                                                label={obj.example.body}
                                                fontWeight={500}
                                            />
                                        </FlexColumn>
                                        <FlexColumn>
                                            {obj.example.child_title && (
                                                <FlexColumn>
                                                    <TitleDiv>{obj.example.child_title}</TitleDiv>
                                                    <Underline />
                                                    <Text
                                                        fontColor={colorsCode.$textColor}
                                                        fontSize={24}
                                                        lineHeight={28}
                                                        label={obj.example.child_body}
                                                        fontWeight={500}
                                                    />
                                                </FlexColumn>
                                            )}
                                        </FlexColumn>
                                    </CardContainer>
                                </FlexColumn>
                            </FlexRow>
                        ))}
                        <FlexRow style={{ marginLeft: '50%', marginBottom: '20px' }}>
                            <Text
                                fontColor={colorsCode.$textColor}
                                fontSize={14}
                                lineHeight={24}
                                label={OHIInsightText.typicalOrgScope}
                                fontWeight={500}
                            />
                        </FlexRow>

                        <FlexRow style={{ width: '100%', marginLeft: '5%' }}>
                            {footerData.map(obj => (
                                <FlexRow
                                    key={obj.key}
                                    style={{ margin: '0px', flexGrow: '1', flexShrink: '0', Bottom: '5px' }}
                                >
                                    <CardContainer key={obj.key}>
                                        <Text
                                            fontColor={colorsCode.$textColor}
                                            fontSize={13}
                                            lineHeight={24}
                                            label={obj.name}
                                            fontWeight={500}
                                        />{' '}
                                    </CardContainer>
                                </FlexRow>
                            ))}
                        </FlexRow>
                    </FlexColumn>
                </OHIInsightContainer>
            </OHIInsightContainer>
        </Fragment>
    );
};

OHIInsight.defaultProps = {
    ohiInsightSteps: [],
    drivenBy: [],
    examples: [],
};

OHIInsight.propTypes = {
    ohiInsightSteps: PropTypes.arrayOf(PropTypes.shape({})),
    drivenBy: PropTypes.arrayOf(PropTypes.shape({})),
    examples: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OHIInsight;
