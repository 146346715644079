import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import Loader from './Loader';

const Unauthenticated = () => {
    const { loading } = useSelector(({ permissionReducer }) => permissionReducer);

    if (loading) {
        return <Loader showBackground position="fixed" background="transparent" data-testid="loader" />;
    }

    return (
        <Box left="0" position="absolute" margin="0" right="0" sx={{ top: '20vh' }} data-testid="unauthenticated">
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" height="500">
                <Typography fontWeight="500" color="black" fontSize={18}>
                    {'Mckinsey & Company'}
                </Typography>
                <br />
                <Typography fontWeight="500" color="#2B5580" fontSize={29}>
                    You&apos;re are not authorized to access this page.
                </Typography>
                <Typography fontWeight="500" color="#2B5580" fontSize={20} variant="caption">
                    Please contact your administrator for permission.
                </Typography>
            </Box>
        </Box>
    );
};

export default Unauthenticated;
