import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import Text from '../Text';
import {
    GridActivityContainer,
    GridActivityRow,
    StyledAccordionSummary,
    StyledAccordionDetails,
    StyledAccordion,
    GapRow,
} from './styles';
import { colorsCode } from '../../../common/colors';
import { PRESURVEY_TEXT } from '../../../utils/kc-constants';

const ActivitesColumn = ({ activities, collapsedItems, setCollapsedItems }) => {
    const toggleCollapse = (expanded, index) => {
        if (expanded) {
            const _expandedItems = collapsedItems.filter(el => el !== index);
            setCollapsedItems(_expandedItems);
        } else {
            setCollapsedItems([...collapsedItems, index]);
        }
    };
    return (
        <GridActivityContainer>
            <GridActivityRow isHeading>
                <Text
                    fontColor={colorsCode.TextHeading}
                    fontSize={16}
                    lineHeight={24}
                    fontWeight={500}
                    label={PRESURVEY_TEXT.KEY_ACTIVITIES_HEADING}
                />
            </GridActivityRow>
            {activities.map((activity, index) => {
                return (
                    <Fragment key={activity.key}>
                        <StyledAccordion
                            expanded={!collapsedItems.includes(index)}
                            onChange={(_, expanded) => toggleCollapse(expanded, index)}
                            style={{
                                background: activity?.bg_color,
                                borderTop: activity?.bg_color ? `1px solid ${colorsCode.Neutral10}` : null,
                            }}
                        >
                            <StyledAccordionSummary
                                isActivityDetail
                                expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                            >
                                <Text
                                    fontColor={colorsCode.TextHeading}
                                    fontSize={16}
                                    lineHeight={24}
                                    fontWeight={500}
                                    label={activity.value}
                                />
                            </StyledAccordionSummary>
                            <StyledAccordionDetails isActivityDetail>
                                {activity.options.map(item => {
                                    return (
                                        <GridActivityRow key={activity.key}>
                                            <Text
                                                fontColor={colorsCode.Neutral80}
                                                fontSize={16}
                                                lineHeight={24}
                                                label={item.value}
                                                maxLines={2}
                                            />
                                        </GridActivityRow>
                                    );
                                })}
                            </StyledAccordionDetails>
                        </StyledAccordion>
                        {index + 1 < activities.length ? <GapRow /> : null}
                    </Fragment>
                );
            })}
        </GridActivityContainer>
    );
};

ActivitesColumn.defaultProps = {
    activities: [],
    collapsedItems: [],
    setCollapsedItems: () => {},
};

ActivitesColumn.propTypes = {
    activities: PropTypes.arrayOf(PropTypes.shape({})),
    collapsedItems: PropTypes.bool,
    setCollapsedItems: PropTypes.func,
};

export default ActivitesColumn;
