import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Logout } from './common/api/login';
import { SessionModal } from './common/DialogModal';
import LoginComponent from './components/Login';
import RoutesComponent from './components/Routes/RoutesComponent';
import { setStatusCode } from './store/reducers/slice/Modal';

function App({ mid }) {
    const dispatch = useDispatch();
    const { statusCode = '' } = useSelector(state => state.dialogReducer);

    const handleClickOnLogout = () => {
        dispatch(setStatusCode(''));
        Logout();
    };

    if (statusCode === 401) {
        return <SessionModal close={handleClickOnLogout} />;
    }

    return (
        <LoginComponent mid={mid} data-testid="login">
            <RoutesComponent mid={mid} />
        </LoginComponent>
    );
}

export default App;
