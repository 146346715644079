import { createSlice } from '@reduxjs/toolkit';
import {
    DeleteDisplatLogic,
    GetDisplayLogicQuestion,
    GetPreviousQuestionList,
    GetSubGroupList,
    SaveDisplayLogic,
} from '../../actions/DisplayLogic/actions';
import { cloneDeep, orderBy } from 'lodash';

const INITIAL_STATE = {
    standardQuestLoading: false,
    loading: false,
    demographicQuestions: [],
    ohiTabs: [],
    ohiTemplateQuestion: [],
    openTextQuestions: [],
    additionalQuestionsData: [],
    subGroupList: [],
    additionalModulesQuestions: [],
    previousQuestionList: [],
    saveDisplayLogicData: '',
    deleteLogicData: { data: false, message: '' },
    subGroupId: null,
};

const DisplayLogic = createSlice({
    name: 'DisplayLogicReducer',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(GetDisplayLogicQuestion.pending, state => {
                state.standardQuestLoading = true;
            })
            .addCase(GetDisplayLogicQuestion.fulfilled, (state, { payload }) => {
                switch (payload.selectedTab) {
                    case 'STANDARD':
                        {
                            const ohiTabs = Object.keys(payload.data);
                            state.ohiTabs = ohiTabs;
                            const clone = cloneDeep(payload.data);
                            ohiTabs?.forEach(item => {
                                if (clone[item]?.Outcome) {
                                    clone[item].Outcome.is_edited = false;
                                    let outcomeQuestions = clone[item].Outcome.questions;
                                    outcomeQuestions?.forEach((val, i) => {
                                        outcomeQuestions[i].is_edited = false;
                                    });
                                    outcomeQuestions = orderBy(outcomeQuestions, ['question_order'], ['asc']);
                                    clone[item].Outcome.questions = outcomeQuestions;
                                }

                                if (clone[item]?.Practices) {
                                    clone[item].Practices.is_edited = false;
                                    let practiceQuestions = clone[item].Practices.questions;
                                    practiceQuestions?.forEach((val, i) => {
                                        practiceQuestions[i].is_edited = false;
                                    });
                                    practiceQuestions = orderBy(practiceQuestions, ['question_order'], ['asc']);
                                    clone[item].Practices.questions = practiceQuestions;
                                }
                            });
                            state.ohiTemplateQuestion = clone;
                        }
                        break;
                    case 'DEMOGRAPHIC':
                        state.demographicQuestions = payload.data;
                        break;
                    case 'OPEN_TEXT':
                        state.openTextQuestions = payload.data;
                        break;
                    case 'ADDITIONAL_QUESTION':
                        state.additionalQuestionsData = payload.data;
                        break;
                    case 'ADDITIONAL_MODULE':
                        state.additionalModulesQuestions = payload.data;
                }
                state.standardQuestLoading = false;
            })
            .addCase(GetDisplayLogicQuestion.rejected, state => {
                state.standardQuestLoading = false;
            })
            .addCase(GetSubGroupList.pending, state => {
                state.loading = true;
            })
            .addCase(GetSubGroupList.fulfilled, (state, { payload }) => {
                state.subGroupList = payload;
                state.loading = false;
            })
            .addCase(GetSubGroupList.rejected, state => {
                state.loading = false;
            })
            .addCase(GetPreviousQuestionList.pending, state => {
                state.loading = true;
            })
            .addCase(GetPreviousQuestionList.fulfilled, (state, { payload }) => {
                state.previousQuestionList = payload;
                state.loading = false;
            })
            .addCase(GetPreviousQuestionList.rejected, state => {
                state.loading = false;
            })
            .addCase(SaveDisplayLogic.pending, state => {
                state.loading = true;
            })
            .addCase(SaveDisplayLogic.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.saveDisplayLogicData = payload;
            })
            .addCase(SaveDisplayLogic.rejected, state => {
                state.loading = false;
            })
            .addCase(DeleteDisplatLogic.pending, state => {
                state.loading = true;
            })
            .addCase(DeleteDisplatLogic.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.deleteLogicData = payload;
            })
            .addCase(DeleteDisplatLogic.rejected, state => {
                state.loading = false;
            });
    },
    reducers: {
        resetdeleteLogicData: state => {
            state.deleteLogicData = { data: false, message: '' };
        },
        resetSaveLogicData: state => {
            state.saveDisplayLogicData = { data: state?.data, message: '' };
        },
        resetOhiTemplateQuestion: state => {
            state.ohiTemplateQuestion = [];
        },
        setSubGroupID: (state, { payload }) => {
            state.subGroupId = payload;
        },
    },
});

export const { resetdeleteLogicData, resetSaveLogicData, resetOhiTemplateQuestion, setSubGroupID } =
    DisplayLogic.actions;
export default DisplayLogic.reducer;
