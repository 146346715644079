import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
// import VersionList from './VersionList';
import HorizontalList from './VersionList';
import { Section } from '../../../common/styled/styled';
import { BackgroundImage } from '../LandingPage/styles';
import overviewBackgroud from '../../../assets/img/overview-background.png';

const VersionPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            <Section>
                <BackgroundImage style={{ position: 'fixed' }} src={overviewBackgroud} alt="overview-background" />
                <HorizontalList />
            </Section>
        </Fragment>
    );
};

VersionPage.propTypes = {
    setVersion: PropTypes.func,
};

export default VersionPage;
