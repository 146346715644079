import React from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import Text from '../../../components/KnowledgeCenter/Text';
import TextWithFootNote from '../../../components/KnowledgeCenter/TextWithFootNote';
import Tooltip from '../../../components/KnowledgeCenter/Tooltip';
import {
    FlexColumnWithAlign,
    FlexRow,
    AccordionStyled,
    AccordionSummaryStyled,
    AccordionDetailsStyled,
} from '../../../common/styled/styled';
import {
    DeploymentContentContainer,
    DeploymentHeadingContainer,
    Divider,
    AccordianContainer,
    AccordionParentDiv,
    ParentContentDiv,
    OutputContainer,
    NoteChip,
} from './styles';
import { ImageContainer, Image } from '../OverviewPage/styles';
import { ReactComponent as HelpIcon } from '../../../assets/img/C-question.svg';
import { LABELS } from '../../../utils/kc-constants';
import { isString } from '../../../utils/functions';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const TimelineAccordian = ({ data, isEven, showPhaseDivider }) => {
    return (
        <AccordionParentDiv>
            {!isEven ? (
                <DeploymentHeadingContainer>
                    <Text fontColor={colorsCode.TextSubtle} fontSize={24} lineHeight={36} label={data.phase} />
                    {showPhaseDivider ? <Divider /> : null}
                </DeploymentHeadingContainer>
            ) : null}
            <ParentContentDiv className="content-div" alignItems="flex-start" gap={pxToVw(43)}>
                <FlexColumnWithAlign>
                    {isEven ? (
                        <DeploymentHeadingContainer>
                            <Text fontColor={colorsCode.TextSubtle} fontSize={24} lineHeight={36} label={data.phase} />
                            {showPhaseDivider ? <Divider /> : null}
                        </DeploymentHeadingContainer>
                    ) : null}
                    <DeploymentContentContainer gap={pxToVh(18)}>
                        <Text
                            fontColor={colorsCode.PrimaryDeepBlue}
                            fontSize={24}
                            lineHeight={28}
                            fontWeight={500}
                            label={data.heading}
                        />
                        {data.header_note ? (
                            <NoteChip
                                fontColor={colorsCode.neutral60}
                                fontSize={16}
                                lineHeight={24}
                                fontWeight={300}
                                backgroundColor={colorsCode.noteBackgroundColor}
                                borderRadius="6px"
                                padding={`${pxToVh(8)}vh ${pxToVw(10)}vw`}
                                label={data.header_note}
                                className="note-chip"
                            />
                        ) : null}
                        <AccordianContainer>
                            {data?.steps?.map((step, index) => {
                                const AccordionTitle = isString(step.heading) ? Text : TextWithFootNote;
                                return (
                                    <AccordionStyled key={step.key} defaultExpanded={index === 0}>
                                        <AccordionSummaryStyled
                                            expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                        >
                                            <AccordionTitle
                                                fontColor={colorsCode.Neutral80}
                                                fontSize={16}
                                                lineHeight={24}
                                                fontWeight={500}
                                                component="p"
                                                footNote={step.heading?.foot_note?.tooltip_text}
                                                footerLabel={step.heading?.foot_note?.label}
                                                label={step?.heading?.text || step?.heading}
                                            />
                                        </AccordionSummaryStyled>
                                        <AccordionDetailsStyled>
                                            <FlexColumnWithAlign gap={pxToVh(4)}>
                                                <Text
                                                    fontColor={colorsCode.Neutral80}
                                                    fontSize={16}
                                                    lineHeight={24}
                                                    label={step.body}
                                                />
                                                {step.note_text ? (
                                                    <NoteChip
                                                        fontColor={colorsCode.neutral60}
                                                        fontSize={16}
                                                        lineHeight={24}
                                                        fontWeight={300}
                                                        backgroundColor={colorsCode.noteBackgroundColor}
                                                        borderRadius="6px"
                                                        padding={`${pxToVh(8)}vh ${pxToVw(10)}vw`}
                                                        label={step.note_text}
                                                        className="note-chip"
                                                    />
                                                ) : null}
                                            </FlexColumnWithAlign>
                                        </AccordionDetailsStyled>
                                    </AccordionStyled>
                                );
                            })}
                        </AccordianContainer>
                        {data?.outputs && data.note_text ? (
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={16}
                                lineHeight={24}
                                label={data.note_text}
                                fontWeight={300}
                                className="note-text"
                            />
                        ) : null}
                        {data?.outputs ? (
                            <OutputContainer gap={pxToVh(4)}>
                                <Text
                                    fontColor={colorsCode.PrimaryDeepBlue}
                                    fontSize={16}
                                    lineHeight={24}
                                    fontWeight={500}
                                    label={LABELS.OUTPUT}
                                />
                                <ol>
                                    {data?.outputs?.map((output, index) => {
                                        return (
                                            <Text
                                                fontColor={colorsCode.TextSubtle}
                                                fontSize={16}
                                                lineHeight={24}
                                                label={output}
                                                component="li"
                                                key={`output_${index}`}
                                            />
                                        );
                                    })}
                                </ol>
                            </OutputContainer>
                        ) : null}
                    </DeploymentContentContainer>
                </FlexColumnWithAlign>
                <FlexColumnWithAlign
                    gap={pxToVh(32)}
                    alignItems={!isEven ? 'flex-end' : null}
                    margin={!isEven ? `0 ${pxToVw(67)}vw 0 0` : null}
                >
                    <FlexRow gap={pxToVw(10)}>
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize={20}
                            lineHeight={32}
                            fontWeight={500}
                            label={data?.stakeholder?.name}
                        />
                        {data?.stakeholder?.help_text ? (
                            <Tooltip text={data?.stakeholder?.help_text}>
                                <HelpIcon className="help-icon" />
                            </Tooltip>
                        ) : null}
                    </FlexRow>
                    <ImageContainer>
                        <Image src={data?.stakeholder?.image_url} alt="deployment-timeline-survey" />
                    </ImageContainer>
                </FlexColumnWithAlign>
            </ParentContentDiv>
        </AccordionParentDiv>
    );
};

TimelineAccordian.defaultProps = {
    data: {},
    isEven: false,
    showPhaseDivider: true,
};

TimelineAccordian.propTypes = {
    data: PropTypes.shape({
        heading: PropTypes.oneOfType(PropTypes.string, PropTypes.shape({})),
        phase: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.shape({})),
        outputs: PropTypes.arrayOf(PropTypes.string),
        stakeholder: PropTypes.shape({
            name: PropTypes.string,
            help_text: PropTypes.string,
            image_url: PropTypes.string,
        }),
    }),
    isEven: PropTypes.bool,
    showPhaseDivider: PropTypes.bool,
};

export default TimelineAccordian;
