import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandMore } from '@mui/icons-material';
import Text from '../../../components/KnowledgeCenter/Text';
import Chip from '../../../components/KnowledgeCenter/Chip';
import {
    AccordionStyled,
    AccordionSummaryStyled,
    AccordionDetailsStyled,
    FlexColumnWithAlign,
    FlexBetween,
} from '../../../common/styled/styled';
import { ImageContainer, Image } from '../OverviewPage/styles';
import { OutcomeContentContainer, StyledDialog } from './styles';
import { GLOSSARY_TEXT } from '../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const OutcomeAndPracticesSection = ({ outcomesData }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuContent, setMenuContent] = useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event, data) => {
        setAnchorEl(event.currentTarget);
        setMenuContent({
            heading: data.text,
            description: data.description,
        });
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuContent(null);
    };

    return (
        <Fragment>
            <FlexColumnWithAlign gap={pxToVh(24)}>
                <FlexColumnWithAlign gap={pxToVh(10)}>
                    <Text
                        fontColor={colorsCode.PrimaryDeepBlue}
                        fontSize={36}
                        lineHeight={44}
                        label={GLOSSARY_TEXT.OUTCOME_SECT_HEADING}
                        fontWeight={500}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={16}
                        lineHeight={24}
                        label={GLOSSARY_TEXT.OUTCOME_SECT_DESCRIPTION}
                    />
                </FlexColumnWithAlign>
                <OutcomeContentContainer gap={pxToVh(45)}>
                    {outcomesData.map((data, index) => {
                        return (
                            <FlexBetween key={data.key} className="content-div" gap={pxToVw(30)}>
                                <FlexColumnWithAlign gap={pxToVh(10)}>
                                    <Chip
                                        label={data.tag.text}
                                        onHover={e => handleClick(e, data.tag)}
                                        onClick={e => handleClick(e, data.tag)}
                                        fontColor={colorsCode.Neutral80}
                                    />
                                    <Text
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={24}
                                        lineHeight={28}
                                        fontWeight={500}
                                        label={data.heading}
                                    />
                                    <Text
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={16}
                                        lineHeight={24}
                                        label={data.body}
                                    />
                                    <Text
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={18}
                                        lineHeight={28}
                                        fontWeight={500}
                                        label={GLOSSARY_TEXT.PRACTICES_TEXT}
                                    />
                                    <FlexColumnWithAlign className="full-width">
                                        {data.practices.map((practice, pIndex) => {
                                            return (
                                                <AccordionStyled
                                                    key={practice.key + pIndex}
                                                    defaultExpanded={pIndex === 0}
                                                    paddingBottom={8}
                                                    noDoubleBorder
                                                >
                                                    <AccordionSummaryStyled
                                                        expandIcon={<ExpandMore htmlColor={colorsCode.Neutral80} />}
                                                    >
                                                        <Text
                                                            fontColor={colorsCode.TextSubtle}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            fontWeight={500}
                                                            label={practice.heading}
                                                        />
                                                    </AccordionSummaryStyled>
                                                    <AccordionDetailsStyled>
                                                        <Text
                                                            fontColor={colorsCode.TextSubtle}
                                                            fontSize={16}
                                                            lineHeight={24}
                                                            label={practice.body}
                                                        />
                                                    </AccordionDetailsStyled>
                                                </AccordionStyled>
                                            );
                                        })}
                                    </FlexColumnWithAlign>
                                </FlexColumnWithAlign>
                                <ImageContainer className="top-center" style={{ marginTop: '10%' }}>
                                    <Image src={data.image_url} alt={`key-activity-${index}`} />
                                </ImageContainer>
                            </FlexBetween>
                        );
                    })}
                </OutcomeContentContainer>
            </FlexColumnWithAlign>
            <StyledDialog
                id="glossary-tag"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                slotProps={{
                    root: {
                        slotProps: {
                            backdrop: {
                                sx: {
                                    background: 'rgba(0, 0, 0, 0.23)',
                                    cursor: 'pointer',
                                },
                            },
                        },
                    },
                }}
            >
                <FlexColumnWithAlign gap={pxToVh(4)}>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={24}
                        lineHeight={36}
                        fontWeight={500}
                        label={menuContent?.heading}
                    />
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize={18}
                        lineHeight={28}
                        fontWeight={300}
                        label={menuContent?.description}
                    />
                </FlexColumnWithAlign>
            </StyledDialog>
        </Fragment>
    );
};

OutcomeAndPracticesSection.defaultProps = {
    outcomesData: [],
};

OutcomeAndPracticesSection.propTypes = {
    outcomesData: PropTypes.arrayOf(PropTypes.shape({})),
};

export default OutcomeAndPracticesSection;
