/* istanbul ignore file */
import { configureStore } from '@reduxjs/toolkit';
import PermissionReducer from './reducers/slice/Login';
import ClientObject from './reducers/slice/ClientSlice';
import Users from './reducers/slice/Users';
import ModalReducer from './reducers/slice/Modal';
import SurveyObject from './reducers/slice/SurveySlice';
import SearchUserClientSlice from './reducers/slice/SearchUserClientSlice';
import ActiveStep from './reducers/slice/ActiveStep';
import BasicInformationSlice from './reducers/slice/CreateSurveySlice';
import TemplatesLanguages from './reducers/slice/TemplatesLanguages';
import UserTemplatesSlice from './reducers/slice/UserTemplates';
import QuestionReducer from './reducers/slice/QuestionReducer';
import Demographics from './reducers/slice/Demographics';
import SettingsSlice from './reducers/slice/SettingsSlice';
import FinalizeReducer from './reducers/slice/FinalizeReducer';
import MonitorResponseSlice from './reducers/slice/MonitorResponseSlice';
import SnackbarSlice from './reducers/slice/SnackbarSlice';
import TextEditSlice from './reducers/slice/TextEditSlice';
import OpenEndSlice from './reducers/slice/OpenEndSlice';
import OhiTeplateQuestionsSlice from './reducers/slice/OhiTeplateQuestionsSlice';
import AdditionalModules from './reducers/slice/AdditionalModules';
import DisplayLogicSlice from './reducers/slice/DisplayLogicSlice';
import ClosingProtocolSlice from './reducers/slice/ClosingProtocolSlice';
import SurveyOverviewSlice from './reducers/slice/SurveyOverview';

export const store = configureStore({
    reducer: {
        permissionReducer: PermissionReducer,
        clientReducer: ClientObject,
        dialogReducer: ModalReducer,
        userReducer: Users,
        surveyReducer: SurveyObject,
        searchData: SearchUserClientSlice,
        activeStepReducer: ActiveStep,
        basicInfo: BasicInformationSlice,
        userTemplates: UserTemplatesSlice,
        templatesLanguages: TemplatesLanguages,
        questionsReducer: QuestionReducer,
        demoReducer: Demographics,
        settingsReducer: SettingsSlice,
        finalSetupReducer: FinalizeReducer,
        monitorResponseReducer: MonitorResponseSlice,
        snackbarReducer: SnackbarSlice,
        tedit: TextEditSlice,
        openText: OpenEndSlice,
        ohiTemp: OhiTeplateQuestionsSlice,
        additionalModule: AdditionalModules,
        displayLogicData: DisplayLogicSlice,
        closingProtocolReducer: ClosingProtocolSlice,
        surveyOverviewReducer: SurveyOverviewSlice,
    },
    devTools: true,
    middleware: getDefaultMiddleware => getDefaultMiddleware(),
});
